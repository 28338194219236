import Actions from '../../../App/interface/actions';
import {
  RouteListActionsTypes,
  IRouteListState
} from '../interfaces/routeList';

const init: IRouteListState = {
  routeList: [],
  activeRouteList: []
};

export function RouteListReducer(state: IRouteListState = init, action: Actions): IRouteListState {
  switch (action.type) {
    case RouteListActionsTypes.ROUTE_LIST:
      return {
       ...state,
       ...{
          routeList: action.payload
        }
      };
    case RouteListActionsTypes.ACTIVE_ROUTE_LIST:
      return {
       ...state,
       ...{
        activeRouteList: action.payload
        }
      };
    default:
      return state;
  }
}