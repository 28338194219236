import { IPage } from "../../App/interface/page";

export const sections: IPage[] = [
    {
        name: "Реестр пациентов",
        path: "/patients/list",
        isActive: true,
        id: "list"
    },
    {
        name: "Регистрация",
        path: "/patients/registration",
        isActive: true,
        id: "reg"
    }
]
