import { HttpService } from "../../../App/services/http"
import {
  ISurgeonInput,
  ISurgeonUpdate
} from "../interfaces/surgeon"

export const SurgeonService = {
  surgeon,
  update,
  create,
}

function create(data: ISurgeonInput) {
  return HttpService.post(`conclutions/surgeon`, data)
    .then(
      response => {
        return response
      })
}

function surgeon(id: number) {
  return HttpService.get(`conclutions/surgeon/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ISurgeonUpdate) {
  return HttpService.patch(`conclutions/surgeon`, data)
    .then(
      response => {
        return response
      })
}
