import Actions from "../../../App/interface/actions";
import {
	TraumatologistOrthopedistActionsTypes,
	ITraumatologistOrthopedistState
} from "../interfaces/traumatologistOrthopedist";


const init: ITraumatologistOrthopedistState = {
	traumatologistOrthopedist: null,
};

export function TraumatologistOrthopedistReducer(state: ITraumatologistOrthopedistState = init, action: Actions): ITraumatologistOrthopedistState {
	switch (action.type) {
		case TraumatologistOrthopedistActionsTypes.TRAUMATOLOGIST_ORTHOPEDIST:
			return {
				...state,
				...{
					traumatologistOrthopedist: action.payload
				}
			};
		case TraumatologistOrthopedistActionsTypes.RESET_TRAUMATOLOGIST_ORTHOPEDIST:
			return {
				...state,
				...{
					traumatologistOrthopedist: null
				}
			};
		default:
			return state;
	}
}