/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { Field, Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';
import { CustomDatePicker } from '../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import { FileUpload } from '../../../App/components/Upload/FileUpload';
import getDiabetesRiskComponent from '../../../App/helpers/getDiabetesRiscComponent';
import { textFieldProps } from '../../../App/helpers/textField';
import { transformData } from '../../../App/helpers/transformData';
import { IRootState } from '../../../App/reducers/store';
import styles from '../../../shared/styles/form';
import { FileActions } from '../../Files/actions/file';
import { FileItem } from '../../Files/interfaces/file';
import { LocalityActions } from '../../Localities/actions/localities';
import { ILocality } from '../../Localities/interfaces/localities';
import { StreetActions } from '../../Streets/actions/streets';
import { IStreet } from '../../Streets/interfaces/streets';
import { PatientActions } from '../actions/patient';
import { IPatientInput } from '../interfaces/input/patient';
import { IPatient } from '../interfaces/patient';
import { EmailField } from '../../../App/components/Input/EmailField';
import { getDisabledForm } from '../../../App/helpers/getDisabledForm';
import { DirtyActionsTypes } from '../../../App/interface/dirty';
import handleNavigate from '../../../App/helpers/clearButtonNavigate';
import { RouteListActions } from '../../RouteList/actions/routeList';

const useStyles = styles;

const Patient = () => {
  const { id } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { localities, locality } = useSelector(
    (state: IRootState) => state.localities
  );
  const { streets, street } = useSelector((state: IRootState) => state.streets);
  const { patient } = useSelector((state: IRootState) => state.patients);
  const { account } = useSelector((state: IRootState) => state.account);
  const { dirty } = useSelector((state: IRootState) => state.dirty);
  const { files } = useSelector((state: IRootState) => state.files);

  const disableEdit =
    getDisabledForm(account, id ?? '', location.pathname) ?? false;

  const [searchStreet, setSearchStreet] = useState('');
  const [searchLocality, setSearchLocality] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [loadingStreet, setLoadingStreet] = useState(false);
  const [loadingStreets, setLoadingStreets] = useState(false);
  const [loadingLocality, setLoadingLocality] = useState(false);
  const [loadingLocalities, setLoadingLocalities] = useState(false);

  const [localitySearchQuery] = useDebounce(searchLocality, 900);
  const [streetSearchQuery] = useDebounce(searchStreet, 900);

  const [objectLocalityId, setObjectLocalityId] = useState<any>(null);

  const getLocalities = async () => {
    return await dispatch(
      LocalityActions.localities({
        name: localitySearchQuery,
        isActual: true,
        isActive: true,
      })
    );
  };

  const getStreets = async () => {
    return await dispatch(
      StreetActions.streets({
        name: streetSearchQuery,
        parentObjectId: objectLocalityId,
        isActual: true,
        isActive: true,
      })
    );
  };

  useEffect(() => {
    if (!loadingLocalities) {
      getLocalities().then(
        () => setLoadingLocalities(true),
        () => setLoadingLocalities(true)
      );
    }
  }, [loadingLocalities]);

  useEffect(() => {
    if (!loadingStreets) {
      getStreets().then(
        () => setLoadingStreets(true),
        () => setLoadingStreets(true)
      );
    }
  }, [loadingStreets]);

  useEffect(() => {
    setLoadingStreets(false);
  }, [streetSearchQuery]);

  useEffect(() => {
    setLoadingLocalities(false);
  }, [localitySearchQuery]);

  useEffect(() => {
    setLoadingStreets(false);
  }, [objectLocalityId]);

  const getStreet = async (id: number) => {
    if (!loadingStreet && id) {
      await dispatch(StreetActions.street(id)).then(
        () => {
          setLoadingStreet(true);
        },
        () => {
          setLoadingStreet(true);
        }
      );
    }
  };

  const getFiles = async (id: number) => {
    if (!loadingFiles) {
      await dispatch(FileActions.files(id)).then(
        () => {
          setLoadingFiles(true);
        },
        () => {
          setLoadingFiles(true);
        }
      );
    }
  };

  const getLocality = async (id: number) => {
    if (!loadingLocality && id) {
      await dispatch(LocalityActions.locality(id)).then(
        () => {
          setLoadingLocality(true);
        },
        () => {
          setLoadingLocality(true);
        }
      );
    }
  };

  const getActiveRouteList = async (patientId?: number) => {
    await dispatch(
      RouteListActions.activeRouteList({idPatient: patientId}),
    );
  };

  const getPatient = async (id: number) => {
    await dispatch(PatientActions.patient(id)).then(
      async (response: IPatient) => {
        await getLocality(response?.objectIdLocality);
        await getStreet(response?.objectIdPlanStreet);
        await getFiles(response?.id);
        await getActiveRouteList(response?.id);
        setObjectLocalityId(response?.objectIdLocality);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (!loading && id) {
      getPatient(parseInt(id)).then(
        () => {
          setLoading(true);
        },
        () => {
          setLoading(true);
        }
      );
    }
  }, [id, loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [id]);

  const submit = (values: FormikValues) => {
    const data = transformData(values);

    let validationError = PatientActions.validate(data as IPatientInput);

    if (validationError) {
      dispatch({
        type: "ALERT_ERROR", payload: {
          message: validationError,
          type: 'error'
        }
      })

      return;
    }

    dispatch(
      patient
        ? PatientActions.update(patient.id, data as IPatientInput)
        : PatientActions.create(data as IPatientInput)
    ).then(
      () => {
        dispatch(FileActions.files(values.id));
        setLoadingLocality(false);
        setLoadingStreet(false);
        setLoading(false);
      },
      () => {}
    );
  };

  const handleSubmitEKG = (files: any) => {
    const formData = new FormData();
    formData.append('files', files[0]);

    dispatch(
      patient &&
        FileActions.sendFile({ idPatient: patient.id, type: 0 }, formData)
    ).then(
      () => {},
      () => {}
    );
  };
  const handleSubmitOnco = (files: any) => {
    const formData = new FormData();
    formData.append('files', files[0]);

    dispatch(
      patient &&
        FileActions.sendFile({ idPatient: patient.id, type: 2 }, formData)
    ).then(
      () => {},
      () => {}
    );
  };

  const handleSubmitBioComp = (files: File[]) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    dispatch(
      patient &&
        FileActions.sendFile({ idPatient: patient.id, type: 1 }, formData)
    ).then(
      () => {},
      () => {}
    );
  };

  const onRemoveFile = (id: number, idPatient: number) => {
    if (id) {
      dispatch(FileActions.delete(id)).then(
        () => {
          dispatch(FileActions.files(idPatient)).then(
            () => {},
            () => {}
          );
        },
        () => {}
      );
    }
  };

  const navigateToInfo = () => {
    navigate(`/patients/${id}/info`);
  };

	useEffect(() => {
		const beforeUnloadHandler = function () {
      return true;
    };
  
    if (dirty) {
      window.onbeforeunload = beforeUnloadHandler;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
	}, [dirty])

  const validationSchema = Yup.object().shape({
    lastName: Yup.mixed().required('Обязательно к заполнению'),
    firstName: Yup.mixed().required('Обязательно к заполнению'),
    gender: Yup.mixed().required('Обязательно к заполнению'),
    birthDate: Yup.mixed().required('Обязательно к заполнению'),
    age: Yup.mixed().required('Обязательно к заполнению'),
    isElevatedSugarOnce: Yup.boolean(),
    isWaistToHip: Yup.boolean(),
    isArterialHypertension: Yup.boolean(),
    isHigherSystolicAp: Yup.boolean(),
    isHighCholesterolDrugs: Yup.boolean(),
    isRelativesDiabetes: Yup.boolean(),
    isPhysicalActivity: Yup.boolean(),
    isHarmfulFoodFtw: Yup.boolean(),
    isElevatedTypeTwoDiabetes: Yup.boolean(),
    isElevatedCancer: Yup.boolean(),
    isSmoking: Yup.boolean(),
    lastMeal: Yup.number().typeError('Должно быть числом').nullable(),
  });

  const getGenderValue = (value: string | number | undefined) => {
    switch (value) {
      case 0:
      case 'Male':
        return 0;
      case 1:
      case 'Female':
        return 1;
    }
  };

  return !id || (id && loading) ? (
    <>
      <Grid className={classes.main}>
        <Formik
          initialValues={{
            id: patient?.id,
            lastName: patient?.lastName,
            firstName: patient?.firstName,
            middleName: patient?.middleName,
            objectLocality: locality,
            objectPlanStreet: street,
            houseNumber: patient?.houseNumber,
            buildingNumber: patient?.buildingNumber,
            appartmentNumber: patient?.appartmentNumber,
            gender: patient?.gender,
            passportSeries: patient?.passportSeries,
            passportNumber: patient?.passportNumber,
            passportIssued: patient?.passportIssued,
            passportIssuedDate: patient?.passportIssuedDate
              ? new Date(patient?.passportIssuedDate)
              : null,
            snils: patient?.snils,
            birthDate: patient?.birthDate ? new Date(patient?.birthDate) : null,
            age: patient?.age,
            lastMeal: patient?.lastMeal,
            isElevatedSugarOnce: !!patient?.isElevatedSugarOnce,
            isWaistToHip: !!patient?.isWaistToHip,
            isArterialHypertension: !!patient?.isArterialHypertension,
            isHigherSystolicAp: !!patient?.isHigherSystolicAp,
            isHighCholesterolDrugs: !!patient?.isHighCholesterolDrugs,
            isRelativesDiabetes: !!patient?.isRelativesDiabetes,
            isPhysicalActivity: !!patient?.isPhysicalActivity,
            isHarmfulFoodFtw: !!patient?.isHarmfulFoodFtw,
            isElevatedTypeTwoDiabetes: !!patient?.isElevatedTypeTwoDiabetes,
            isElevatedCancer: !!patient?.isElevatedCancer,
            isSmoking: !!patient?.isSmoking,
            email: patient?.email,
            phone: patient?.phone,
            createdAt: patient?.createdAt,
          }}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ errors, values, setFieldValue, dirty }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              dispatch({ type: DirtyActionsTypes.DIRTY, payload: dirty });
            }, [dirty]);
            return (
              <Form>
                <Grid container className={classes.formRow}>
                  <Grid item>
                    <InputLabel>Фамилия *</InputLabel>
                    <Field
                      required
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`lastName`}
                      inputProps={textFieldProps}
                      helperText={errors.lastName}
                      component={TextFieldWithError}
                      error={Boolean(errors.lastName)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Имя *</InputLabel>
                    <Field
                      required
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`firstName`}
                      inputProps={textFieldProps}
                      helperText={errors.firstName}
                      component={TextFieldWithError}
                      error={Boolean(errors.firstName)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Отчество</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`middleName`}
                      inputProps={textFieldProps}
                      helperText={errors.middleName}
                      component={TextFieldWithError}
                      error={Boolean(errors.middleName)}
                    />
                  </Grid>
                  <Grid item>
                    <Stack direction="column">
                      <label
                        style={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          letterSpacing: '0.00938em',
                        }}
                      >
                        Пол *
                      </label>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          width: '230px',
                        }}
                      >
                        <Select
                          id="gender"
                          displayEmpty
                          disabled={disableEdit}
                          value={getGenderValue(values.gender)}
                          disableUnderline
                          required
                          labelId="gender-label"
                          onChange={(e) => {
                            setFieldValue('gender', e.target.value);
                          }}
                          renderValue={(value) => {
                            if (value === 0) {
                              return 'Мужской';
                            } else if (value === 1) {
                              return 'Женский';
                            } else {
                              return null;
                            }
                          }}
                          sx={{
                            '& .MuiSelect-iconOutlined': {
                              display: values.gender ? 'none' : '',
                            },
                            '& .MuiSelect-icon': {
                              display: values.gender ? 'none' : '',
                            },
                            '&.Mui-focused .MuiIconButton-root': {
                              color: 'primary.main',
                            },
                          }}
                          endAdornment={
                            <IconButton
                              onClick={() => {
                                setFieldValue('gender', null);
                              }}
                              sx={{
                                visibility: values.gender
                                  ? 'visible'
                                  : 'hidden',
                                padding: '6px',
                              }}
                            >
                              <ClearIcon sx={{ fontSize: 16, padding: 0 }} />
                            </IconButton>
                          }
                        >
                          <MenuItem value={0}>Мужской</MenuItem>
                          <MenuItem value={1}>Женский</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 14px !important',
                      },
                    }}
                  >
                    <InputLabel>Дата рождения *</InputLabel>
                    <CustomDatePicker
                      required
                      disableFuture
                      disabled={disableEdit}
                      value={values?.birthDate}
                      inputFormat="dd/MM/yyyy"
                      views={['year', 'month', 'day']}
                      onChange={(newValue: any) =>
                        setFieldValue('birthDate', newValue)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Возраст</InputLabel>
                    <Field
                      disabled
                      size="small"
                      type="number"
                      name={`age`}
                      align="center"
                      variant="outlined"
                      helperText={errors.age}
                      inputProps={textFieldProps}
                      error={Boolean(errors.age)}
                      component={TextFieldWithError}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.formRow}>
                  <Grid item>
                    <InputLabel sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                      Город регистрации
                    </InputLabel>
                    <Autocomplete
                      disablePortal
                      filterSelectedOptions
                      id="objectLocality"
                      options={localities}
                      size="small"
                      disabled={disableEdit}
                      value={values.objectLocality}
                      onChange={(
                        e: React.SyntheticEvent<Element, Event>,
                        value: ILocality | null
                      ) => {
                        setFieldValue('objectLocality', value);
                        setObjectLocalityId(value?.objectId);
                        setFieldValue('objectPlanStreet', null);
                      }}
                      getOptionLabel={(option: ILocality) =>
                        `${option?.name} ${
                          option?.nameParentArea
                            ? ` - ${option?.nameParentArea}`
                            : ''
                        } ${
                          option?.nameParentRegion
                            ? ` - ${option?.nameParentRegion}`
                            : ''
                        }`
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          autoComplete={'disabled'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchLocality(e.target.value)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                      Адрес регистрации
                    </InputLabel>
                    <Autocomplete
                      disablePortal
                      filterSelectedOptions
                      id="objectPlanStreet"
                      options={streets}
                      size="small"
                      disabled={disableEdit}
                      value={values.objectPlanStreet}
                      onChange={(
                        e: React.SyntheticEvent<Element, Event>,
                        value: IStreet | null
                      ) => setFieldValue('objectPlanStreet', value)}
                      getOptionLabel={(option: IStreet) =>
                        `${option?.name.trim()}${
                          option?.typeName ? `, ${option?.typeName}` : ''
                        }`
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          autoComplete={'disabled'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchStreet(e.target.value)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Дом</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`houseNumber`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.houseNumber}
                      error={Boolean(errors.houseNumber)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Строение / корпус</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`buildingNumber`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.buildingNumber}
                      error={Boolean(errors.buildingNumber)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Квартира</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`appartmentNumber`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.appartmentNumber}
                      error={Boolean(errors.appartmentNumber)}
                    />
                  </Grid>
                </Grid>

                <Grid className={classes.formRow}>
                  <Grid item>
                    <InputLabel>Паспорт серия</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`passportSeries`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.passportSeries}
                      error={Boolean(errors.passportSeries)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Паспорт номер</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`passportNumber`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.passportNumber}
                      error={Boolean(errors.passportNumber)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Паспорт выдан</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      variant="outlined"
                      name={`passportIssued`}
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                      helperText={errors.passportIssued}
                      error={Boolean(errors.passportIssued)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 14px !important',
                      },
                    }}
                  >
                    <InputLabel>Дата выдачи</InputLabel>
                    <CustomDatePicker
                      disableFuture
                      disabled={disableEdit}
                      inputFormat="dd/MM/yyyy"
                      views={['year', 'month', 'day']}
                      value={values?.passportIssuedDate}
                      onChange={(newValue: any) =>
                        setFieldValue('passportIssuedDate', newValue)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>СНИЛС</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      name={`snils`}
                      variant="outlined"
                      helperText={errors.snils}
                      inputProps={textFieldProps}
                      error={Boolean(errors.snils)}
                      component={TextFieldWithError}
                    />
                  </Grid>
                  <Grid item sx={{ height: '60px' }}>
                    <InputLabel>Электронная почта</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      name={`email`}
                      variant="outlined"
                      component={EmailField}
                      inputProps={textFieldProps}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Телефон</InputLabel>
                    <Field
                      type="text"
                      size="small"
                      align="center"
                      name={`phone`}
                      variant="outlined"
                      inputProps={textFieldProps}
                      component={TextFieldWithError}
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    gap: '1rem',
                    display: 'flex',
                    padding: '0 1rem',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={2} sx={{ pt: 5 }}>
                    <Grid item xs={12}>
                      {patient?.diabetesCalculationValue &&
                        getDiabetesRiskComponent(
                          patient.diabetesCalculationValue
                        )}
                    </Grid>
                    <Grid container sx={{ paddingTop: '20px' }}>
                      <Grid item>
                        <InputLabel sx={{ width: '400px' }}>
                          Последний прием пищи (кол-во часов назад)
                        </InputLabel>
                        <Field
                          type="text"
                          size="small"
                          align="center"
                          name={`lastMeal`}
                          variant="outlined"
                          disabled={disableEdit}
                          inputProps={textFieldProps}
                          component={TextFieldWithError}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="isElevatedSugarOnce">
                        Выявление хотя бы 1 раз повышенного уровня сахара в
                        капиллярной / венозной крови натощак *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isElevatedSugarOnce}
                        onChange={(event, value) =>
                          setFieldValue('isElevatedSugarOnce', value === 'true')
                        }
                      >
                        <FormControlLabel
                          disabled={disableEdit}
                          value={true}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          disabled={disableEdit}
                          value={false}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={6}>
                      <Typography id="isWaistToHip">
                        Соотношение окружности талии к окружности бедер
                        Waist-to-hip ratio более или равно 0.85 для женщин и
                        более или равно 0.92 для мужчин *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isWaistToHip}
                        onChange={(event, value) =>
                          setFieldValue('isWaistToHip', value === 'true')
                        }
                      >
                        <FormControlLabel
                          disabled={disableEdit}
                          value={true}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          disabled={disableEdit}
                          value={false}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={6}>
                      <Typography id="isArterialHypertension">
                        Наличие артериальной гипертензии *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isArterialHypertension}
                        onChange={(event, value) =>
                          setFieldValue(
                            'isArterialHypertension',
                            value === 'true'
                          )
                        }
                      >
                        <FormControlLabel
                          disabled={disableEdit}
                          value={true}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          disabled={disableEdit}
                          value={false}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Grid item xs={6}>
                      <Typography id="isHigherSystolicAp">
                        Привычное систолическое АД выше или равно 132 мм рт. ст.
                        *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isHigherSystolicAp}
                        onChange={(event, value) =>
                          setFieldValue('isHigherSystolicAp', value === 'true')
                        }
                      >
                        <FormControlLabel
                          disabled={disableEdit}
                          value={true}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          disabled={disableEdit}
                          value={false}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Grid item xs={6}>
                      <Typography id="isHighCholesterolDrugs">
                        Прием препаратов для коррекции повышенного холестерина
                        крови (факт приема) *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isHighCholesterolDrugs}
                        onChange={(event, value) =>
                          setFieldValue(
                            'isHighCholesterolDrugs',
                            value === 'true'
                          )
                        }
                      >
                        <FormControlLabel
                          value={true}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          value={false}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Grid item xs={6}>
                      <Typography id="isRelativesDiabetes">
                        Наличие диагноза СД 2 типа у кровных родственников
                        (родители, дети, братья, сестры) *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isRelativesDiabetes}
                        onChange={(event, value) =>
                          setFieldValue('isRelativesDiabetes', value === 'true')
                        }
                      >
                        <FormControlLabel
                          value={true}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          value={false}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={6}>
                      <Typography id="isHarmfulFoodFtw">
                        Потребление консервированных и/или копченых и/или
                        избыточно соленых продуктов от 4 раз в неделю и более *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isHarmfulFoodFtw}
                        onChange={(event, value) =>
                          setFieldValue('isHarmfulFoodFtw', value === 'true')
                        }
                      >
                        <FormControlLabel
                          value={true}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Да"
                        />
                        <FormControlLabel
                          value={false}
                          disabled={disableEdit}
                          control={<Radio />}
                          label="Нет"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={6}>
                      <Typography id="isPhysicalActivity">
                        Регулярность физической нагрузки *
                      </Typography>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        value={values.isPhysicalActivity}
                        onChange={(event, value) =>
                          setFieldValue('isPhysicalActivity', value === 'true')
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          disabled={disableEdit}
                          label="Да (не менее 3х раз в неделю по не менее 30 мин)"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          disabled={disableEdit}
                          label="Нет (физическая нагрузка отсутствует, менее 3х раз в неделю менее 30 мин)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.formRow}
                  sx={{ paddingTop: '20px;' }}
                >
                  <Grid item xs={6}>
                    <Typography id="isElevatedTypeTwoDiabetes">
                      Был ли ранее выявлен СД 2 типа
                    </Typography>
                  </Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      value={values.isElevatedTypeTwoDiabetes}
                      onChange={(event, value) =>
                        setFieldValue(
                          'isElevatedTypeTwoDiabetes',
                          value === 'true'
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Да"
                      />
                      <FormControlLabel
                        value={false}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Нет"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Grid item xs={6}>
                    <Typography id="isElevatedCancer">
                      Были ли ранее выявлены онкозаболевания?
                    </Typography>
                  </Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      value={values.isElevatedCancer}
                      onChange={(event, value) =>
                        setFieldValue('isElevatedCancer', value === 'true')
                      }
                    >
                      <FormControlLabel
                        value={true}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Да"
                      />
                      <FormControlLabel
                        value={false}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Нет"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Grid item xs={6}>
                    <Typography id="isSmoking">Курите ли вы?</Typography>
                  </Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      value={values.isSmoking}
                      onChange={(event, value) =>
                        setFieldValue('isSmoking', value === 'true')
                      }
                    >
                      <FormControlLabel
                        value={true}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Да"
                      />
                      <FormControlLabel
                        value={false}
                        disabled={disableEdit}
                        control={<Radio />}
                        label="Нет"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid container spacing={2} sx={{ pt: 4 }}>
                  <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    <FileUpload
                      disable={
                        !(
                          account?.idFunction === 1 ||
                          account?.idFunction === null
                        )
                      }
                      maxFiles={1}
                      label="Прикрепить ЭКГ"
                      onSubmit={handleSubmitEKG}
                    />
                    {files &&
                      files
                        ?.filter((file: FileItem) => file.type === 'Ecg')
                        .map((file: FileItem) => (
                          <Grid item xs={12} key={file.id}>
                            <Link
                              target="_blank"
                              underline={'none'}
                              href={`${
                                process.env.REACT_APP_API_URL
                              }/files/file/${
                                file.id
                              }?access_token=${localStorage.getItem('token')}`}
                              onClick={() =>
                                dispatch(FileActions.file(file.id))
                              }
                            >
                              {file.fileName}
                            </Link>
                            <IconButton
                              onClick={() =>
                                onRemoveFile(file.id, file.idPatient)
                              }
                            >
                              <DeleteOutlineIcon style={{ color: red[500] }} />
                            </IconButton>
                          </Grid>
                        ))}
                  </Grid>
                  <Grid item xs={4}>
                    <FileUpload
                      disable={disableEdit}
                      maxFiles={Infinity}
                      label="Прикрепить Биоимпедансометрию"
                      onSubmit={handleSubmitBioComp}
                    />
                    {files &&
                      files
                        ?.filter(
                          (file: FileItem) => file.type === 'Bioimpensadometry'
                        )
                        .map((file: FileItem) => (
                          <Grid item xs={12} key={file.id}>
                            <Link
                              target="_blank"
                              underline={'none'}
                              href={`${
                                process.env.REACT_APP_API_URL
                              }/files/file/${
                                file.id
                              }?access_token=${localStorage.getItem('token')}`}
                              onClick={() =>
                                dispatch(FileActions.file(file.id))
                              }
                            >
                              {file.fileName}
                            </Link>
                            <IconButton
                              onClick={() =>
                                onRemoveFile(file.id, file.idPatient)
                              }
                            >
                              <DeleteOutlineIcon style={{ color: red[500] }} />
                            </IconButton>
                          </Grid>
                        ))}
                  </Grid>
                  <Grid item xs={4}>
                    <FileUpload
                      maxFiles={1}
                      label="Прикрепить Онкоскрининг"
                      onSubmit={handleSubmitOnco}
                      disable={
                        !(
                          account?.idFunction === 4 ||
                          account?.idFunction === null
                        )
                      }
                    />
                    {files &&
                      files
                        ?.filter(
                          (file: FileItem) => file.type === 'СancerScreening'
                        )
                        .map((file: FileItem) => (
                          <Grid item xs={12} key={file.id}>
                            <Link
                              target="_blank"
                              underline={'none'}
                              href={`${
                                process.env.REACT_APP_API_URL
                              }/files/file/${
                                file.id
                              }?access_token=${localStorage.getItem('token')}`}
                              onClick={() =>
                                dispatch(FileActions.file(file.id))
                              }
                            >
                              {file.fileName}
                            </Link>
                            <IconButton
                              onClick={() =>
                                onRemoveFile(file.id, file.idPatient)
                              }
                            >
                              <DeleteOutlineIcon style={{ color: red[500] }} />
                            </IconButton>
                          </Grid>
                        ))}
                  </Grid>
                </Grid>
                <br />
                {(!disableEdit ||
                  account?.idFunction === 1 ||
                  account?.idFunction === 4) && (
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingTop: 5,
                    }}
                    direction="row"
                    spacing={2}
                  >
                    <Button variant="contained" color="success" type="submit">
                      Сохранить данные
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleNavigate(dirty, navigateToInfo)}
                    >
                      Отменить заполнение
                    </Button>
                  </Stack>
                )}
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </>
  ) : null;
};

export default Patient;
