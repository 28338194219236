import Actions from "../../../App/interface/actions";
import {
	SurgeonActionsTypes,
	ISurgeonState
} from "../interfaces/surgeon";


const init: ISurgeonState = {
	surgeon: null,
};

export function SurgeonReducer(state: ISurgeonState = init, action: Actions): ISurgeonState {
	switch (action.type) {
		case SurgeonActionsTypes.SURGEON:
			return {
				...state,
				...{
					surgeon: action.payload
				}
			};
		case SurgeonActionsTypes.RESET_SURGEON:
			return {
				...state,
        ...{
          surgeon: null
        }
      };
		default:
			return state;
	}
}