import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import { RouteListService } from "../services/routeList"
import { RouteListActionsTypes } from "../interfaces/routeList"

export const RouteListActions = {
  routeList,
  activeRouteList,
  activate,
  deactivate
}

function routeList() {
  return (dispatch: any) => new Promise((resolve, reject) => {
    RouteListService.routeList()
      .then(
        response => {
          dispatch({type: RouteListActionsTypes.ROUTE_LIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
              type: AlertActionsTypes.ERROR, payload: {
                  message: error.message,
                  type: 'error'
              }
          })
          reject(error.message)
        }
      )
  })
}

function activeRouteList(params: any) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    RouteListService.activeRouteList(params)
      .then(
        response => {
          dispatch({type: RouteListActionsTypes.ACTIVE_ROUTE_LIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
              type: AlertActionsTypes.ERROR, payload: {
                  message: error.message,
                  type: 'error'
              }
          })
          reject(error.message)
        }
      )
  })
}

function activate(id: number) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    RouteListService.activate(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({
              type: AlertActionsTypes.ERROR, payload: {
                  message: error.message,
                  type: 'error'
              }
          })
          reject(error.message)
        }
      )
  })
}

function deactivate(id: number) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    RouteListService.deactivate(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({
              type: AlertActionsTypes.ERROR, payload: {
                  message: error.message,
                  type: 'error'
              }
          })
          reject(error.message)
        }
      )
  })
}