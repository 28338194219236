import AccountCircle from '@mui/icons-material/AccountCircle';
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getFullName } from '../../../../App/helpers/getFullName';
import { IPage } from "../../../../App/interface/page";
import { IRootState } from '../../../../App/reducers/store';
import { AuthActions } from '../../../../Auth/actions/auth';
import OnlineBadge from './onlineBadge';
import { DirtyActionsTypes } from '../../../../App/interface/dirty';

const userMenu: IPage[] = [
    {
        name: "Профиль",
        path: "/profile",
        isActive: true
    },
    {
        name: "Выйти",
        path: "/logout",
        isActive: true
    }
];

export const UserMenu = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const {dirty} = useSelector((state: IRootState) => state.dirty)
    const {account} = useSelector((state: IRootState) => state.account)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        return dispatch(AuthActions.logout()).then(
            () => {
                localStorage.clear()
            }
        )
    }

    const handleTabClick = (page: IPage) => {
        const confirmed = window.confirm('Перейти на эту страницу без сохранения данных?');
        if (confirmed) {
            dispatch({type: DirtyActionsTypes.DIRTY, payload: false})
            navigate(page.path);
        }
      };

    return (
        <Box className={'profileName'} sx={{flexGrow: 0, m: 1, display: 'flex', alignItems: 'center'}}>
            <div className={'profileNameUser'}>
			{account &&
                <Tooltip title={getFullName(account)} placement="left-start">
                    <div>{account.userName}</div>
                </Tooltip>
            }
            </div>
            <Tooltip title={getFullName(account)}>
                <Stack direction="row" spacing={2}>
                    <OnlineBadge
                        variant="dot"
                        overlap="circular"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                        <IconButton onClick={handleOpenUserMenu} size="large">
                            <AccountCircle sx={{display: 'flex'}} fontSize="inherit"/>
                        </IconButton>
                    </OnlineBadge>
                </Stack>
            </Tooltip>
            <Menu
                keepMounted
                id="menu-appbar"
                sx={{mt: '45px'}}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {userMenu.map((userMenuItem) => (
                    <MenuItem
                        key={userMenuItem.name}
                        onClick={() => dirty ? handleTabClick(userMenuItem) : navigate(userMenuItem.path)}
                    >
                        <Typography textAlign="center">
                            {userMenuItem.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
