import Actions from "../../../App/interface/actions";
import {
	OphthalmologistActionsTypes,
	IOphthalmologistState
} from "../interfaces/ophthalmologist";


const init: IOphthalmologistState = {
	ophthalmologist: null,
};

export function OphthalmologistReducer(state: IOphthalmologistState = init, action: Actions): IOphthalmologistState {
	switch (action.type) {
		case OphthalmologistActionsTypes.OPHTHALMOLOGIST:
			return {
				...state,
				...{
					ophthalmologist: action.payload
				}
			};
		case OphthalmologistActionsTypes.RESET_OPHTHALMOLOGIST:
			return {
				...state,
				...{
					ophthalmologist: null
				}
			};
		default:
			return state;
	}
}