import Actions from "../../../App/interface/actions";
import {
	PhlebologistActionsTypes,
	IPhlebologistState
} from "../interfaces/phlebologist";


const init: IPhlebologistState = {
	phlebologist: null,
};

export function PhlebologistReducer(state: IPhlebologistState = init, action: Actions): IPhlebologistState {
	switch (action.type) {
		case PhlebologistActionsTypes.PHLEBOLOGIST:
			return {
				...state,
				...{
					phlebologist: action.payload
				}
			};
		case PhlebologistActionsTypes.RESET_PHLEBOLOGIST:
			return {
				...state,
				...{
					phlebologist: null
				}
			};
		default:
			return state;
	}
}