import Actions from "../../../App/interface/actions";
import {
	GastroenterologistActionsTypes,
	IGastroenterologistState
} from "../interfaces/gastroenterologist";


const init: IGastroenterologistState = {
	gastroenterologist: null,
};

export function GastroenterologistReducer(state: IGastroenterologistState = init, action: Actions): IGastroenterologistState {
	switch (action.type) {
		case GastroenterologistActionsTypes.GASTROENTEROLOGIST:
			return {
				...state,
				...{
					gastroenterologist: action.payload
				}
			};
		case GastroenterologistActionsTypes.RESET_GASTROENTEROLOGIST:
			return {
				...state,
				...{
					gastroenterologist: null
				}
			};
		default:
			return state;
	}
}