import { HttpService } from "../../../App/services/http"
import {
  IPulmonologistInput,
  IPulmonologistUpdate
} from "../interfaces/pulmonologist"

export const PulmonologistService = {
  pulmonologist,
  update,
  create,
}

function create(data: IPulmonologistInput) {
  return HttpService.post(`conclutions/pulmonologist`, data)
    .then(
      response => {
        return response
      })
}

function pulmonologist(id: number) {
  return HttpService.get(`conclutions/pulmonologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IPulmonologistUpdate) {
  return HttpService.patch(`conclutions/pulmonologist`, data)
    .then(
      response => {
        return response
      })
}
