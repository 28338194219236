export interface IOncologistMammologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOncologistMammologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IOncologistMammologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOncologistMammologistState {
  oncologistMammologist: IOncologistMammologist | null
}

export enum OncologistMammologistActionsTypes {
  ONCOLOGIST_MAMMOLOGIST = 'ONCOLOGIST_MAMMOLOGIST',
  RESET_ONCOLOGIST_MAMMOLOGIST = 'RESET_ONCOLOGIST_MAMMOLOGIST'
}
