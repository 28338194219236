import { HttpService } from "../../../App/services/http"
import {
  IOtolaryngologistInput,
  IOtolaryngologistUpdate
} from "../interfaces/otolaryngologist"

export const OtolaryngologistService = {
  otolaryngologist,
  update,
  create,
}

function create(data: IOtolaryngologistInput) {
  return HttpService.post(`conclutions/otolaryngologist`, data)
    .then(
      response => {
        return response
      })
}

function otolaryngologist(id: number) {
  return HttpService.get(`conclutions/otolaryngologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IOtolaryngologistUpdate) {
  return HttpService.patch(`conclutions/otolaryngologist`, data)
    .then(
      response => {
        return response
      })
}
