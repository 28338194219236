import Actions from "../../../App/interface/actions";
import {
	PulmonologistActionsTypes,
	IPulmonologistState
} from "../interfaces/pulmonologist";


const init: IPulmonologistState = {
	pulmonologist: null,
};

export function PulmonologistReducer(state: IPulmonologistState = init, action: Actions): IPulmonologistState {
	switch (action.type) {
		case PulmonologistActionsTypes.PULMONOLOGIST:
			return {
				...state,
				...{
					pulmonologist: action.payload
				}
			};
		case PulmonologistActionsTypes.RESET_PULMONOLOGIST:
			return {
        ...state,
        ...{
          pulmonologist: null
        }
      };
		default:
			return state;
	}
}