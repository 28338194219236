export interface IPulmonologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IPulmonologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IPulmonologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IPulmonologistState {
  pulmonologist: IPulmonologist | null
}

export enum PulmonologistActionsTypes {
  PULMONOLOGIST = 'PULMONOLOGIST',
  RESET_PULMONOLOGIST = 'RESET_PULMONOLOGIST'
}
