import { HttpService } from "../../../App/services/http"
import {
  ICardiologistInput,
  ICardiologistUpdate
} from "../interfaces/cardiologist"

export const CardiologistService = {
  cardiologist,
  update,
  create,
}

function create(data: ICardiologistInput) {
  return HttpService.post(`conclutions/cardiologist`, data)
    .then(
      response => {
        return response
      })
}

function cardiologist(id: number) {
  return HttpService.get(`conclutions/cardiologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ICardiologistUpdate) {
  return HttpService.patch(`conclutions/cardiologist`, data)
    .then(
      response => {
        return response
      })
}
