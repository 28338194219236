export interface IUrologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IUrologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IUrologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IUrologistState {
  urologist: IUrologist | null
}

export enum UrologistActionsTypes {
  UROLOGIST = 'UROLOGIST',
  RESET_UROLOGIST = 'RESET_UROLOGIST'
}
