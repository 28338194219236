export interface IOtolaryngologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOtolaryngologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IOtolaryngologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOtolaryngologistState {
  otolaryngologist: IOtolaryngologist | null
}

export enum OtolaryngologistActionsTypes {
  OTOLARYNGOLOGIST = 'OTOLARYNGOLOGIST',
  RESET_OTOLARYNGOLOGIST = 'RESET_OTOLARYNGOLOGIST'
}
