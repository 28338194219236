export interface IPhlebologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IPhlebologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IPhlebologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IPhlebologistState {
  phlebologist: IPhlebologist | null
}

export enum PhlebologistActionsTypes {
  PHLEBOLOGIST = 'PHLEBOLOGIST',
  RESET_PHLEBOLOGIST = 'RESET_PHLEBOLOGIST'
}
