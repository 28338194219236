import Actions from "../../../App/interface/actions";
import {
	OtolaryngologistActionsTypes,
	IOtolaryngologistState
} from "../interfaces/otolaryngologist";


const init: IOtolaryngologistState = {
	otolaryngologist: null,
};

export function OtolaryngologistReducer(state: IOtolaryngologistState = init, action: Actions): IOtolaryngologistState {
	switch (action.type) {
		case OtolaryngologistActionsTypes.OTOLARYNGOLOGIST:
			return {
				...state,
				...{
					otolaryngologist: action.payload
				}
			};
		case OtolaryngologistActionsTypes.RESET_OTOLARYNGOLOGIST:
			return {
				...state,
				...{
					otolaryngologist: null
				}
			};
		default:
			return state;
	}
}