import { HttpService } from "../../../App/services/http"
import {
  IUrologistInput,
  IUrologistUpdate
} from "../interfaces/urologist"

export const UrologistService = {
  urologist,
  update,
  create,
}

function create(data: IUrologistInput) {
  return HttpService.post(`conclutions/urologist`, data)
    .then(
      response => {
        return response
      })
}

function urologist(id: number) {
  return HttpService.get(`conclutions/urologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IUrologistUpdate) {
  return HttpService.patch(`conclutions/urologist`, data)
    .then(
      response => {
        return response
      })
}
