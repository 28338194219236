export interface IColoproctologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IColoproctologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IColoproctologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IColoproctologistState {
  coloproctologist: IColoproctologist | null
}

export enum ColoproctologistActionsTypes {
  COLOPROCTOLOGIST = 'COLOPROCTOLOGIST',
  RESET_COLOPROCTOLOGIST = 'RESET_COLOPROCTOLOGIST'
}
