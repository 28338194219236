import Actions from "../../../App/interface/actions";
import {
	CardiologistActionsTypes,
	ICardiologistState
} from "../interfaces/cardiologist";


const init: ICardiologistState = {
	cardiologist: null,
};

export function CardiologistReducer(state: ICardiologistState = init, action: Actions): ICardiologistState {
	switch (action.type) {
		case CardiologistActionsTypes.CARDIOLOGIST:
			return {
				...state,
				...{
					cardiologist: action.payload
				}
			};
		case CardiologistActionsTypes.RESET_CARDIOLOGIST:
			return {
				...state,
				...{
					cardiologist: null
				}
			};
		default:
			return state;
	}
}