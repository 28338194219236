import { HttpService } from "../../../App/services/http"
import {
  IPhlebologistInput,
  IPhlebologistUpdate
} from "../interfaces/phlebologist"

export const PhlebologistService = {
  phlebologist,
  update,
  create,
}

function create(data: IPhlebologistInput) {
  return HttpService.post(`conclutions/phlebologist`, data)
    .then(
      response => {
        return response
      })
}

function phlebologist(id: number) {
  return HttpService.get(`conclutions/phlebologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IPhlebologistUpdate) {
  return HttpService.patch(`conclutions/phlebologist`, data)
    .then(
      response => {
        return response
      })
}
