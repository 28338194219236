import { IPage } from "../../App/interface/page";
import { IPatient } from "../../features/Patient/interfaces/patient";
import { IRouteList } from "../../features/RouteList/interfaces/routeList";

const INFO_PAGE = {
	name: "Регистратор",
	path: "/patients/:id/info",
	isActive: true,
	id: "basic"
}

const BLOOD_COUNT_PAGE = {
	name: "Медсестра",
	path: "/patients/:id/blood-count",
	isActive: true,
	id: "blood"
}

const BREAST_ULTRASOUND_PAGE = {
	name: "УЗИ молочных желёз",
	path: "/patients/:id/breast-ultrasound",
	isActive: true,
	id: "uzi_mol"
}

const CANCER_SCREENING_PAGE = {
	name: "Онкоскрининг",
	path: "/patients/:id/cancer-screening",
	isActive: true,
	id: "onco"
}

const THYROID_ULTRASOUND_PAGE = {
	name: "УЗИ щитовидной железы",
	path: "/patients/:id/thyroid-ultrasound",
	isActive: true,
	id: "uzi_chit"
}

const CARDIAC_SURGEON_PAGE = {
	name: "Кардиохирург",
	path: "/patients/:id/cardiac-surgeon",
	isActive: true,
	id: "doc_cardiac_surgeon"
}

const CARDIOLOGIST_PAGE = {
	name: "Кардиолог",
	path: "/patients/:id/cardiologist",
	isActive: true,
	id: "doc_cardiologist"
}

const COLOPROCTOLOGIST_PAGE = {
	name: "Колопроктолог",
	path: "/patients/:id/coloproctologist",
	isActive: true,
	id: "doc_coloproctologist"
}

const GASTROENTEROLOGIST_PAGE = {
	name: "Гастроэнтеролог",
	path: "/patients/:id/gastroenterologist",
	isActive: true,
	id: "doc_gastroenterologist"
}

const NEUROLOGIST_PAGE = {
	name: "Невролог",
	path: "/patients/:id/neurologist",
	isActive: true,
	id: "doc_neurologist"
}

const NEUROSURGEON_PAGE = {
	name: "Нейрохирург",
	path: "/patients/:id/neurosurgeon",
	isActive: true,
	id: "doc_neurosurgeon"
}

const OBSTETRICIAN_GYNECOLOGIST_PAGE = {
	name: "Акушер-гинеколог",
	path: "/patients/:id/obstetrician-gynecologist",
	isActive: true,
	id: "doc_obstetrician_gynecologist"
}

const ONCOLOGIST_PAGE = {
	name: "Онколог",
	path: "/patients/:id/oncologist",
	isActive: true,
	id: "doc_oncologist"
}

const ONCOLOGIST_MAMMOLOGIST_PAGE = {
	name: "Онколог-маммолог",
	path: "/patients/:id/oncologist-mammologist",
	isActive: true,
	id: "doc_oncologist_mammologist"
}

const OPHTHALMOLOGIST_PAGE = {
	name: "Офтальмолог",
	path: "/patients/:id/ophthalmologist",
	isActive: true,
	id: "doc_ophthalmologist"
}

const OTOLARYNGOLOGIST_PAGE = {
	name: "Отоларинголог",
	path: "/patients/:id/otolaryngologist",
	isActive: true,
	id: "doc_otolaryngologist"
}

const PHLEBOLOGIST_PAGE = {
	name: "Флеболог",
	path: "/patients/:id/phlebologist",
	isActive: true,
	id: "doc_phlebologist"
}

const PULMONOLOGIST_PAGE = {
	name: "Пульмонолог",
	path: "/patients/:id/pulmonologist",
	isActive: true,
	id: "doc_pulmonologist"
}

const RHEUMATOLOGIST_PAGE = {
	name: "Ревматолог",
	path: "/patients/:id/rheumatologist",
	isActive: true,
	id: "doc_rheumatologist"
}

const SURGEON_PAGE = {
	name: "Хирург",
	path: "/patients/:id/surgeon",
	isActive: true,
	id: "doc_surgeon"
}

const TRAUMATOLOGIST_ORTHOPEDIST_PAGE = {
	name: "Травматолог-ортопед",
	path: "/patients/:id/traumatologist-orthopedist",
	isActive: true,
	id: "doc_traumatologist_orthopedist"
}

const UROLOGIST_PAGE = {
	name: "Уролог",
	path: "/patients/:id/urologist",
	isActive: true,
	id: "doc_urologist"
}

const ENDOCRINOLOGIST_PAGE = {
	name: "Эндокринолог",
	path: "/patients/:id/endocrinologist",
	isActive: true,
	id: "doc_endo"
}

const THERAPIST_PAGE = {
	name: "Терапевт",
	path: "/patients/:id/therapist",
	isActive: true,
	id: "doc_tera"
}
export const tabs = [
	INFO_PAGE,
	BLOOD_COUNT_PAGE,
	THYROID_ULTRASOUND_PAGE,
	BREAST_ULTRASOUND_PAGE,
	CARDIAC_SURGEON_PAGE,
	CARDIOLOGIST_PAGE,
	COLOPROCTOLOGIST_PAGE,
	GASTROENTEROLOGIST_PAGE,
	NEUROLOGIST_PAGE,
	NEUROSURGEON_PAGE,
	OBSTETRICIAN_GYNECOLOGIST_PAGE,
	ONCOLOGIST_PAGE,
	ONCOLOGIST_MAMMOLOGIST_PAGE,
	OPHTHALMOLOGIST_PAGE,
	OTOLARYNGOLOGIST_PAGE,
	PHLEBOLOGIST_PAGE,
	PULMONOLOGIST_PAGE,
	RHEUMATOLOGIST_PAGE,
	SURGEON_PAGE,
	TRAUMATOLOGIST_ORTHOPEDIST_PAGE,
	UROLOGIST_PAGE,
	THERAPIST_PAGE,
	ENDOCRINOLOGIST_PAGE,
	CANCER_SCREENING_PAGE,
];
export const getTabs = (positionId: number, role: string): IPage[] => {
	switch (role) {
		case 'MedicalStaff':
			switch (positionId) {
				case 1:
					return [BLOOD_COUNT_PAGE];
				case 2:
					return [THYROID_ULTRASOUND_PAGE, BREAST_ULTRASOUND_PAGE];
				case 3:
					return [ENDOCRINOLOGIST_PAGE];
				case 4:
					return [THERAPIST_PAGE, CANCER_SCREENING_PAGE];
				case 5:
					return [SURGEON_PAGE];
        case 6:
					return [OBSTETRICIAN_GYNECOLOGIST_PAGE];
				case 7:
					return [UROLOGIST_PAGE];
        case 8:
					return [OTOLARYNGOLOGIST_PAGE];
				case 9:
					return [TRAUMATOLOGIST_ORTHOPEDIST_PAGE];
        case 10:
					return [NEUROLOGIST_PAGE];
				case 11:
					return [ONCOLOGIST_PAGE];
        case 12:
					return [GASTROENTEROLOGIST_PAGE];
				case 13:
					return [RHEUMATOLOGIST_PAGE];
        case 14:
					return [NEUROSURGEON_PAGE];
				case 15:
					return [ONCOLOGIST_MAMMOLOGIST_PAGE];
				case 16:
					return [COLOPROCTOLOGIST_PAGE];
				case 17:
					return [PULMONOLOGIST_PAGE];
				case 18:
					return [CARDIOLOGIST_PAGE];
				case 19:
					return [CARDIAC_SURGEON_PAGE];
				case 20:
					return [PHLEBOLOGIST_PAGE];
				case 21:
					return [OPHTHALMOLOGIST_PAGE];
				default:
					return []
			}
		case 'Receptionist':
			return [INFO_PAGE]
		case 'Admin':
			return [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				BREAST_ULTRASOUND_PAGE,
				CARDIAC_SURGEON_PAGE,
				CARDIOLOGIST_PAGE,
				COLOPROCTOLOGIST_PAGE,
				GASTROENTEROLOGIST_PAGE,
				NEUROLOGIST_PAGE,
				NEUROSURGEON_PAGE,
				OBSTETRICIAN_GYNECOLOGIST_PAGE,
				ONCOLOGIST_PAGE,
				ONCOLOGIST_MAMMOLOGIST_PAGE,
				OPHTHALMOLOGIST_PAGE,
				OTOLARYNGOLOGIST_PAGE,
				PHLEBOLOGIST_PAGE,
				PULMONOLOGIST_PAGE,
				RHEUMATOLOGIST_PAGE,
				SURGEON_PAGE,
				TRAUMATOLOGIST_ORTHOPEDIST_PAGE,
				UROLOGIST_PAGE,
				THERAPIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				CANCER_SCREENING_PAGE,
			];
		default:
			return []
	}
}

export const getPatientTabs = (patient: IPatient, activeRouteList: IRouteList[]): IPage[] => {
	let tabs: IPage[] = [];

	switch (patient.gender) {
		case 'Female':
			tabs = [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				BREAST_ULTRASOUND_PAGE,
				CANCER_SCREENING_PAGE,
				OBSTETRICIAN_GYNECOLOGIST_PAGE,
				GASTROENTEROLOGIST_PAGE,
				CARDIAC_SURGEON_PAGE,
				CARDIOLOGIST_PAGE,
				COLOPROCTOLOGIST_PAGE,
				NEUROLOGIST_PAGE,
				NEUROSURGEON_PAGE,
				ONCOLOGIST_PAGE,
				ONCOLOGIST_MAMMOLOGIST_PAGE,
				OTOLARYNGOLOGIST_PAGE,
				OPHTHALMOLOGIST_PAGE,
				PULMONOLOGIST_PAGE,
				RHEUMATOLOGIST_PAGE,
				SURGEON_PAGE,
				TRAUMATOLOGIST_ORTHOPEDIST_PAGE,
				UROLOGIST_PAGE,
				PHLEBOLOGIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				THERAPIST_PAGE
			];
			break;
		case 'Male':
			tabs = [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				CANCER_SCREENING_PAGE,
				GASTROENTEROLOGIST_PAGE,
				CARDIAC_SURGEON_PAGE,
				CARDIOLOGIST_PAGE,
				COLOPROCTOLOGIST_PAGE,
				NEUROLOGIST_PAGE,
				NEUROSURGEON_PAGE,
				ONCOLOGIST_PAGE,
				OTOLARYNGOLOGIST_PAGE,
				OPHTHALMOLOGIST_PAGE,
				PULMONOLOGIST_PAGE,
				RHEUMATOLOGIST_PAGE,
				TRAUMATOLOGIST_ORTHOPEDIST_PAGE,
				UROLOGIST_PAGE,
				SURGEON_PAGE,
				PHLEBOLOGIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				THERAPIST_PAGE
			];
			break;
		default:
			tabs = []
			break;
	}

	tabs.forEach((tab: IPage) => {
		const route = activeRouteList.find((service: IRouteList) => service.code === tab.id);

		if (!route && tab.id !== 'basic') {
			tab.isActive = false;
		} else {
			tab.isActive = true;
		}
	});

	return tabs;
}
