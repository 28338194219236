export interface IOphthalmologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOphthalmologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IOphthalmologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOphthalmologistState {
  ophthalmologist: IOphthalmologist | null
}

export enum OphthalmologistActionsTypes {
  OPHTHALMOLOGIST = 'OPHTHALMOLOGIST',
  RESET_OPHTHALMOLOGIST = 'RESET_OPHTALMOLOGIST'
}
