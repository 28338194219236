import { HttpService } from "../../../App/services/http"
import {
  ICardiacSurgeonInput,
  ICardiacSurgeonUpdate
} from "../interfaces/cardiacSurgeon"

export const CardiacSurgeonService = {
  cardiacSurgeon,
  update,
  create,
}

function create(data: ICardiacSurgeonInput) {
  return HttpService.post(`conclutions/cardiac-surgeon`, data)
    .then(
      response => {
        return response
      })
}

function cardiacSurgeon(id: number) {
  return HttpService.get(`conclutions/cardiac-surgeon/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ICardiacSurgeonUpdate) {
  return HttpService.patch(`conclutions/cardiac-surgeon`, data)
    .then(
      response => {
        return response
      })
}
