import { HttpService } from "../../../App/services/http"
import {
  IObstetricianGynecologistInput,
  IObstetricianGynecologistUpdate
} from "../interfaces/obstetricianGynecologist"

export const ObstetricianGynecologistService = {
  obstetricianGynecologist,
  update,
  create,
}

function create(data: IObstetricianGynecologistInput) {
  return HttpService.post(`conclutions/obstetrician-gynecologist`, data)
    .then(
      response => {
        return response
      })
}

function obstetricianGynecologist(id: number) {
  return HttpService.get(`conclutions/obstetrician-gynecologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IObstetricianGynecologistUpdate) {
  return HttpService.patch(`conclutions/obstetrician-gynecologist`, data)
    .then(
      response => {
        return response
      })
}
