import React, { Suspense } from "react"
import { useRoutes } from "react-router";
import { Profile } from "../Account/components/Profile";
import Account from "../Auth/Account";
import Login from "../Auth/Login";
import Logout from "../Auth/Logout";
import { BloodCountPage } from "../features/Analysis/components";
import Endocrinologist from "../features/Conclusions/components/Endocrinologist";
import Therapist from "../features/Conclusions/components/Therapist";
import Patient from "../features/Patient/components/Patient";
import Thyroid from "../features/Researches/components/Thyroid";
import { User } from "../features/User/components/User";
import { AdminProxyPage } from "../pages/AdminProxyPage";
import { AdminRegistrationPage } from "../pages/AdminRegistrationPage";
import AdminRolesPage from "../pages/AdminRegistrationPage/component/AdminRegistrationPage";
import { AdminUsersPage } from "../pages/AdminUsersPage";
import { AuditPage } from "../pages/AuditPage";
import { ErrorPage } from "../pages/ErrorPage";
import PatientProxy from "../pages/PatientProxy/components/PatientProxy";
import Patients from "../pages/Patients/component/Patients";
import { PatientsPage } from "../pages/PatientsPage";
import { RegistrationPage } from "../pages/RegistrationPage";
import { App } from "./App";
import CancerScreening from "../features/Researches/components/CancerScreening";
import BreastUltrasound from "../features/Researches/components/breastUltrasound";
import CardiacSurgeon from "../features/Conclusions/components/CardiacSurgeon";
import Cardiologist from "../features/Conclusions/components/Cardiologist";
import Coloproctologist from "../features/Conclusions/components/Coloproctologist";
import Gastroenterologist from "../features/Conclusions/components/Gastroenterologist";
import Neurologist from "../features/Conclusions/components/Neurologist";
import Neurosurgeon from "../features/Conclusions/components/Neurosurgeon";
import ObstetricianGynecologist from "../features/Conclusions/components/ObstetricianGynecologist";
import Oncologist from "../features/Conclusions/components/Oncologist";
import OncologistMammologist from "../features/Conclusions/components/OncologistMammologist";
import Ophthalmologist from "../features/Conclusions/components/Ophthalmologist";
import Otolaryngologist from "../features/Conclusions/components/Otolaryngologist";
import Phlebologist from "../features/Conclusions/components/Phlebologist";
import Pulmonologist from "../features/Conclusions/components/Pulmonologist";
import Rheumatologist from "../features/Conclusions/components/Rheumatologist";
import Surgeon from "../features/Conclusions/components/Surgeon";
import TraumatologistOrthopedist from "../features/Conclusions/components/TraumatologistOrthopedist";
import Urologist from "../features/Conclusions/components/Urologist";
import { AdminRouteList } from "../pages/AdminRouteList";

export default function Routes(): JSX.Element {
	const routing = useRoutes([
		{
			path: '/',
			element: <Account><App/></Account>,
			children: [
				// {
				// 	path: 'user',
				// 	element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><UserPage/></Account></Suspense>
				// },
				{
					path: '/profile',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><Profile/></Account></Suspense>
				},
				{
					path: "patients",
					element: <Suspense
					  fallback={<div className="loading">Загружается...</div>}><Account><PatientsPage/></Account></Suspense>,
					children: [
						{
							path: "list",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><Patients/></Account></Suspense>,
						},
						{
							path: "registration",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><RegistrationPage/></Account></Suspense>,
						},
						{
							path: ":id",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><PatientProxy/></Account></Suspense>,
							children: [
								{
									path: "info",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["Receptionist", "MedicalStaff"]}
									  positionIds={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]}><Patient/></Account></Suspense>,
								},
								{
									path: "blood-count",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[1]}><BloodCountPage/></Account></Suspense>,
								},
								{
									path: "thyroid-ultrasound",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><Thyroid/></Account></Suspense>,
								},
								{
									path: "breast-ultrasound",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><BreastUltrasound/></Account></Suspense>,
								},
								{
									path: "cancer-screening",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><CancerScreening/></Account></Suspense>,
								},
								{
									path: "cardiac-surgeon",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[19]}><CardiacSurgeon/></Account></Suspense>,
								},
								{
									path: "cardiologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[18]}><Cardiologist/></Account></Suspense>,
								},
								{
									path: "coloproctologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[16]}><Coloproctologist/></Account></Suspense>,
								},
								{
									path: "gastroenterologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[12]}><Gastroenterologist/></Account></Suspense>,
								},
								{
									path: "neurologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[10]}><Neurologist/></Account></Suspense>,
								},
								{
									path: "neurosurgeon",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[14]}><Neurosurgeon/></Account></Suspense>,
								},
								{
									path: "obstetrician-gynecologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[6]}><ObstetricianGynecologist/></Account></Suspense>,
								},
								{
									path: "oncologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[11]}><Oncologist/></Account></Suspense>,
								},
								{
									path: "oncologist-mammologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[15]}><OncologistMammologist/></Account></Suspense>,
								},
								{
									path: "ophthalmologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[21]}><Ophthalmologist/></Account></Suspense>,
								},
								{
									path: "otolaryngologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[8]}><Otolaryngologist/></Account></Suspense>,
								},
								{
									path: "phlebologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[20]}><Phlebologist/></Account></Suspense>,
								},
								{
									path: "pulmonologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[17]}><Pulmonologist/></Account></Suspense>,
								},
								{
									path: "rheumatologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[13]}><Rheumatologist/></Account></Suspense>,
								},
								{
									path: "surgeon",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[5]}><Surgeon/></Account></Suspense>,
								},
								{
									path: "traumatologist-orthopedist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[9]}><TraumatologistOrthopedist/></Account></Suspense>,
								},
								{
									path: "urologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[7]}><Urologist/></Account></Suspense>,
								},
								{
									path: "endocrinologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[3]}><Endocrinologist/></Account></Suspense>,
								},
								{
									path: "therapist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
										positionIds={[4]}><Therapist/></Account></Suspense>,
								},
							]
						},
					]
				},
				{
					path: 'admin',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account roles={["Admin"]}><AdminProxyPage/></Account></Suspense>,
					children: [
						{
							path: "registration",
							element: <Suspense fallback={
								<div className="loading">Загружается...</div>}><Account><AdminRegistrationPage/></Account></Suspense>,
						},
						{
							path: "users",
							element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><AdminUsersPage/></Account></Suspense>,
						},
						{
							path: "user/edit/:id",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><User/></Account></Suspense>,
						},
						{
							path: "roles",
							element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><AdminRolesPage/></Account></Suspense>,
						},
						{
							path: "route-list",
							element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><AdminRouteList/></Account></Suspense>,
						}
					]
				},
				{
					path: 'audit',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
					  roles={["Admin"]}><AuditPage/></Account></Suspense>
				},
			]
		},
		{path: '/login', element: <Login/>},
		{path: '/logout', element: <Logout/>},
		{path: '*', element: <ErrorPage/>}
	]);

	return <React.Fragment>{routing}</React.Fragment>;
}
