import Actions from "../../../App/interface/actions";
import {
	NeurologistActionsTypes,
	INeurologistState
} from "../interfaces/neurologist";


const init: INeurologistState = {
	neurologist: null,
};

export function NeurologistReducer(state: INeurologistState = init, action: Actions): INeurologistState {
	switch (action.type) {
		case NeurologistActionsTypes.NEUROLOGIST:
			return {
				...state,
				...{
					neurologist: action.payload
				}
			};
		case NeurologistActionsTypes.RESET_NEUROLOGIST:
			return {
				...state,
				...{
					neurologist: null
				}
			};
		default:
			return state;
	}
}