const positions = [
	{
		userName: 'Медицинская сестра',
		type: 'Nurse',
		id: 1,
	},
	{
		userName: 'Врач УЗД',
		type: 'DoctorUd',
		id: 2,
	},
	{
		userName: 'Терапевт',
		type: 'Therapist',
		id: 4,
	},
	{
		userName: 'Эндокринолог',
		type: 'Endocrinologist',
		id: 3,
	},
	{
		userName: 'Хирург',
		type: 'Surgeon',
		id: 5,
	},
	{
		userName: 'Акушер-гинеколог',
		type: 'ObstetricianGynecologist',
		id: 6,
	},
	{
		userName: 'Уролог',
		type: 'Urologist',
		id: 7,
	},
	{
		userName: 'Отоларинголог',
		type: 'Otolaryngologist',
		id: 8,
	},
	{
		userName: 'Травматолог-ортопед',
		type: 'TraumatologistOrthopedist',
		id: 9,
	},
	{
		userName: 'Невролог',
		type: 'Neurologist',
		id: 10,
	},
	{
		userName: 'Онколог',
		type: 'Oncologist',
		id: 11,
	},
	{
		userName: 'Гастроэнтеролог',
		type: 'Gastroenterologist',
		id: 12,
	},
	{
		userName: 'Ревматолог',
		type: 'Rheumatologist',
		id: 13,
	},
	{
		userName: 'Нейрохирург',
		type: 'Neurosurgeon',
		id: 14,
	},
	{
		userName: 'Онколог-маммолог',
		type: 'OncologistMammologist',
		id: 15,
	},
	{
		userName: 'Колопроктолог',
		type: 'Coloproctologist',
		id: 16,
	},
	{
		userName: 'Пульмонолог',
		type: 'Pulmonologist',
		id: 17,
	},
	{
		userName: 'Кардиолог',
		type: 'Cardiologist',
		id: 18,
	},
	{
		userName: 'Кардиохирург',
		type: 'CardiacSurgeon',
		id: 19,
	},
	{
		userName: 'Флеболог',
		type: 'Phlebologist',
		id: 20,
	},
	{
		userName: 'Офтальмолог',
		type: 'Ophthalmologist',
		id: 21,
	},
];

const getPositionNameById = (role: any, id: unknown) => {
	if ((typeof role === 'string' && role !== 'MedicalStaff' && role !== 'Мед.персонал')
	  ||
	  (typeof role === 'number' && role !== 0)) {
		return null
	} else {
		const position = positions.find((position) => position.id === id);
		if (position) {
			return position.userName;
		}
	}
}

export {positions, getPositionNameById};