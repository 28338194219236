import Actions from "../../../App/interface/actions";
import {
	ObstetricianGynecologistActionsTypes,
	IObstetricianGynecologistState
} from "../interfaces/obstetricianGynecologist";


const init: IObstetricianGynecologistState = {
	obstetricianGynecologist: null,
};

export function ObstetricianGynecologistReducer(state: IObstetricianGynecologistState = init, action: Actions): IObstetricianGynecologistState {
	switch (action.type) {
		case ObstetricianGynecologistActionsTypes.OBSTETRICIAN_GYNECOLOGIST:
			return {
				...state,
				...{
					obstetricianGynecologist: action.payload
				}
			};
		case ObstetricianGynecologistActionsTypes.RESER_OBSTETRICIAN_GYNECOLOGIST:
			return {
				...state,
        ...{
          obstetricianGynecologist: null
        }
      };
		default:
			return state;
	}
}