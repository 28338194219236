import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import {
  OtolaryngologistActionsTypes,
  IOtolaryngologistInput,
  IOtolaryngologistUpdate
} from "../interfaces/otolaryngologist"
import { OtolaryngologistService } from "../services/otolaryngologist";


export const OtolaryngologistActions = {
    otolaryngologist,
    create,
    update
}
 
function create(data: IOtolaryngologistInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OtolaryngologistService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Заключение отоларинголога добавлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function otolaryngologist(id: number) {
  return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OtolaryngologistService.otolaryngologist(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: OtolaryngologistActionsTypes.OTOLARYNGOLOGIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(data: IOtolaryngologistUpdate) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OtolaryngologistService.update(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Данные пациента обновлены успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}
