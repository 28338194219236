export interface INeurologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface INeurologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface INeurologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface INeurologistState {
  neurologist: INeurologist | null
}

export enum NeurologistActionsTypes {
  NEUROLOGIST = 'NEUROLOGIST',
  RESET_NEUROLOGIST = 'RESET_NEUROLOGIST'
}
