import Actions from "../../../App/interface/actions";
import {
	OncologistMammologistActionsTypes,
	IOncologistMammologistState
} from "../interfaces/oncologistMammologist";


const init: IOncologistMammologistState = {
	oncologistMammologist: null,
};

export function OncologistMammologistReducer(state: IOncologistMammologistState = init, action: Actions): IOncologistMammologistState {
	switch (action.type) {
		case OncologistMammologistActionsTypes.ONCOLOGIST_MAMMOLOGIST:
			return {
				...state,
				...{
					oncologistMammologist: action.payload
				}
			};
		case OncologistMammologistActionsTypes.RESET_ONCOLOGIST_MAMMOLOGIST:
			return {
				...state,
				...{
					oncologistMammologist: null
				}
			};
		default:
			return state;
	}
}