import { HttpService } from "../../../App/services/http"

export const RouteListService = {
  routeList,
  activeRouteList,
  activate,
  deactivate
}

function routeList() {
  return HttpService.get(`route-list`)
    .then(
      response => {
        return response
      })
}

function activeRouteList(params: any) {
  return HttpService.get(`route-list/active`, params)
    .then(
      response => {
        return response
      })
}

function activate(id: number) {
  return HttpService.patch(`route-list/${id}/activate`)
    .then(
      response => {
        return response
      })
}

function deactivate(id: number) {
  return HttpService.patch(`route-list/${id}/deactivate`)
    .then(
      response => {
        return response
      })
}
