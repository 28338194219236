import { HttpService } from "../../../App/services/http"
import {
  INeurologistInput,
  INeurologistUpdate
} from "../interfaces/neurologist"

export const NeurologistService = {
  neurologist,
  update,
  create,
}

function create(data: INeurologistInput) {
  return HttpService.post(`conclutions/neurologist`, data)
    .then(
      response => {
        return response
      })
}

function neurologist(id: number) {
  return HttpService.get(`conclutions/neurologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: INeurologistUpdate) {
  return HttpService.patch(`conclutions/neurologist`, data)
    .then(
      response => {
        return response
      })
}
