import { HttpService } from "../../../App/services/http"
import {
  IOncologistInput,
  IOncologistUpdate
} from "../interfaces/oncologist"

export const OncologistService = {
  oncologist,
  update,
  create,
}

function create(data: IOncologistInput) {
  return HttpService.post(`conclutions/oncologist`, data)
    .then(
      response => {
        return response
      })
}

function oncologist(id: number) {
  return HttpService.get(`conclutions/oncologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IOncologistUpdate) {
  return HttpService.patch(`conclutions/oncologist`, data)
    .then(
      response => {
        return response
      })
}
