import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import {
  CardiologistActionsTypes,
  ICardiologistInput,
  ICardiologistUpdate
} from "../interfaces/cardiologist"
import { CardiologistService } from "../services/cardiologist";


export const CardiologistActions = {
    cardiologist,
    create,
    update
}
 
function create(data: ICardiologistInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return CardiologistService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Заключение кардиолога добавлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function cardiologist(id: number) {
  return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return CardiologistService.cardiologist(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: CardiologistActionsTypes.CARDIOLOGIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(data: ICardiologistUpdate) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return CardiologistService.update(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Данные пациента обновлены успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}
