import Actions from "../../../App/interface/actions";
import {
	UrologistActionsTypes,
	IUrologistState
} from "../interfaces/urologist";


const init: IUrologistState = {
	urologist: null,
};

export function UrologistReducer(state: IUrologistState = init, action: Actions): IUrologistState {
	switch (action.type) {
		case UrologistActionsTypes.UROLOGIST:
			return {
				...state,
				...{
					urologist: action.payload
				}
			};
		case UrologistActionsTypes.RESET_UROLOGIST:
			return {
				...state,
				...{
					urologist: null
				}
			};
		default:
			return state;
	}
}