import {
  applyMiddleware,
  combineReducers,
  createStore
} from 'redux';
import logger from "redux-logger";
import thunk, { ThunkDispatch } from "redux-thunk";
import { IAccountState } from "../../Account/interface/account";
import { AccountReducer } from "../../Account/reducers/account";
import { IBloodTestState } from "../../features/Analysis/interfaces/bloodTest";
import { BloodTestReducer } from "../../features/Analysis/reducer/bloodTest";
import { IBreastUltrasoundState } from '../../features/Researches/interfaces/breastUltrasound';
import { BreastUltrasoundReducer } from '../../features/Researches/reducers/breastUltrasound';
import { IEndocrinologistState } from '../../features/Conclusions/interfaces/endocrinologist';
import { ITherapistState } from '../../features/Conclusions/interfaces/therapist';
import { EndocrinologistReducer } from '../../features/Conclusions/reducers/endocrinologist';
import { TherapistReducer } from '../../features/Conclusions/reducers/therapist';
import { IFileState } from '../../features/Files/interfaces/file';
import { FileReducer } from '../../features/Files/reducers/file';
import { ILocalityState } from "../../features/Localities/interfaces/localities";
import { LocalityReducer } from "../../features/Localities/reducer/locality";
import { IMcbState } from "../../features/Mcb/interfaces/mcb";
import { McbReducer } from "../../features/Mcb/reducer/mcb";
import { IPatientState } from '../../features/Patient/interfaces/patient';
import { PatientReducer } from '../../features/Patient/reducer/patient';
import { ICancerScreeningState } from '../../features/Researches/interfaces/cancerScreening';
import { IThyroidState } from '../../features/Researches/interfaces/thyroid';
import { CancerScreeningReducer } from '../../features/Researches/reducers/cancerScreening';
import { ThyroidReducer } from '../../features/Researches/reducers/thyroid';
import { IStreetState } from '../../features/Streets/interfaces/streets';
import { StreetReducer } from '../../features/Streets/reducer/streets';
import { IUserState } from '../../features/User/interfaces/user';
import { UserReducer } from '../../features/User/reducer/user';
import { IAlertsState } from "../interface/alert";
import { ILoadingState } from "../interface/loading";
import { AlertReducer } from "./alert";
import { LoadingReducer } from "./loading";
import { DirtyReducer } from './dirty';
import { IDirtyState } from '../interface/dirty';
import { CardiacSurgeonReducer } from '../../features/Conclusions/reducers/cardiacSurgeon';
import { CardiologistReducer } from '../../features/Conclusions/reducers/cardiologist';
import { ColoproctologistReducer } from '../../features/Conclusions/reducers/coloproctologist';
import { GastroenterologistReducer } from '../../features/Conclusions/reducers/gastroenterologist';
import { NeurologistReducer } from '../../features/Conclusions/reducers/neurologist';
import { NeurosurgeonReducer } from '../../features/Conclusions/reducers/neurosurgeon';
import { ObstetricianGynecologistReducer } from '../../features/Conclusions/reducers/obstetricianGynecologist';
import { OncologistReducer } from '../../features/Conclusions/reducers/oncologist';
import { OncologistMammologistReducer } from '../../features/Conclusions/reducers/oncologistMammologist';
import { OtolaryngologistReducer } from '../../features/Conclusions/reducers/otolaryngologist';
import { OphthalmologistReducer } from '../../features/Conclusions/reducers/ophthalmologist';
import { PhlebologistReducer } from '../../features/Conclusions/reducers/phlebologist';
import { PulmonologistReducer } from '../../features/Conclusions/reducers/pulmonologist';
import { RheumatologistReducer } from '../../features/Conclusions/reducers/rheumatologist';
import { SurgeonReducer } from '../../features/Conclusions/reducers/surgeon';
import { TraumatologistOrthopedistReducer } from '../../features/Conclusions/reducers/traumatologistOrthopedist';
import { UrologistReducer } from '../../features/Conclusions/reducers/urologist';
import { ICardiacSurgeonState } from '../../features/Conclusions/interfaces/cardiacSurgeon';
import { ICardiologistState } from '../../features/Conclusions/interfaces/cardiologist';
import { IColoproctologistState } from '../../features/Conclusions/interfaces/coloproctologist';
import { IGastroenterologistState } from '../../features/Conclusions/interfaces/gastroenterologist';
import { INeurologistState } from '../../features/Conclusions/interfaces/neurologist';
import { INeurosurgeonState } from '../../features/Conclusions/interfaces/neurosurgeon';
import { IObstetricianGynecologistState } from '../../features/Conclusions/interfaces/obstetricianGynecologist';
import { IOncologistState } from '../../features/Conclusions/interfaces/oncologist';
import { IOncologistMammologistState } from '../../features/Conclusions/interfaces/oncologistMammologist';
import { IOphthalmologistState } from '../../features/Conclusions/interfaces/ophthalmologist';
import { IOtolaryngologistState } from '../../features/Conclusions/interfaces/otolaryngologist';
import { IPhlebologistState } from '../../features/Conclusions/interfaces/phlebologist';
import { IPulmonologistState } from '../../features/Conclusions/interfaces/pulmonologist';
import { IRheumatologistState } from '../../features/Conclusions/interfaces/rheumatologist';
import { ISurgeonState } from '../../features/Conclusions/interfaces/surgeon';
import { ITraumatologistOrthopedistState } from '../../features/Conclusions/interfaces/traumatologistOrthopedist';
import { IUrologistState } from '../../features/Conclusions/interfaces/urologist';
import { RouteListReducer } from '../../features/RouteList/reducer/routeList';
import { IRouteListState } from '../../features/RouteList/interfaces/routeList';

const rootReducer = combineReducers<IRootState>({
  breastUltrasound: BreastUltrasoundReducer,
  cancerScreening: CancerScreeningReducer,
  cardiacSurgeon: CardiacSurgeonReducer,
  cardiologist: CardiologistReducer,
  coloproctologist: ColoproctologistReducer,
  gastroenterologist: GastroenterologistReducer,
  neurologist: NeurologistReducer,
  neurosurgeon: NeurosurgeonReducer,
  obstetricianGynecologist: ObstetricianGynecologistReducer,
  oncologist: OncologistReducer,
  oncologistMammologist: OncologistMammologistReducer,
  ophthalmologist: OphthalmologistReducer,
  otolaryngologist: OtolaryngologistReducer,
  phlebologist: PhlebologistReducer,
  pulmonologist: PulmonologistReducer,
  rheumatologist: RheumatologistReducer,
  surgeon: SurgeonReducer,
  traumatologistOrthopedist: TraumatologistOrthopedistReducer,
  urologist: UrologistReducer,
  endocrinologist: EndocrinologistReducer,
  therapist: TherapistReducer,
  patients: PatientReducer,
  account: AccountReducer,
  loading: LoadingReducer,
  thyroid: ThyroidReducer,
  alerts: AlertReducer,
  bloodTest: BloodTestReducer,
  localities: LocalityReducer,
  streets: StreetReducer,
  dirty: DirtyReducer,
  users: UserReducer,
  files: FileReducer,
  mcbs: McbReducer,
  routeList: RouteListReducer
});

export interface IRootState {
  breastUltrasound: IBreastUltrasoundState,
  cancerScreening: ICancerScreeningState,
  cardiacSurgeon: ICardiacSurgeonState,
  cardiologist: ICardiologistState,
  coloproctologist: IColoproctologistState,
  gastroenterologist: IGastroenterologistState,
  neurologist: INeurologistState,
  neurosurgeon: INeurosurgeonState,
  obstetricianGynecologist: IObstetricianGynecologistState,
  oncologist: IOncologistState,
  oncologistMammologist: IOncologistMammologistState,
  ophthalmologist: IOphthalmologistState,
  otolaryngologist: IOtolaryngologistState,
  phlebologist: IPhlebologistState,
  pulmonologist: IPulmonologistState,
  rheumatologist: IRheumatologistState,
  surgeon: ISurgeonState,
  traumatologistOrthopedist: ITraumatologistOrthopedistState,
  urologist: IUrologistState,
  endocrinologist: IEndocrinologistState,
  therapist: ITherapistState,
  patients: IPatientState,
  account: IAccountState,
  loading: ILoadingState,
  thyroid: IThyroidState,
  alerts: IAlertsState,
  bloodTest: IBloodTestState,
  localities: ILocalityState,
  streets: IStreetState,
  dirty: IDirtyState,
  users: IUserState,
  files: IFileState,
  mcbs: IMcbState,
  routeList: IRouteListState,
}

export type RootState = ReturnType<typeof rootReducer>;
type AppAction = ReturnType<typeof store.dispatch>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger),
);

export type Dispatch = ThunkDispatch<RootState, never, AppAction>;
