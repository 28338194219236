export interface IOncologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOncologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IOncologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IOncologistState {
  oncologist: IOncologist | null
}

export enum OncologistActionsTypes {
  ONCOLOGIST = 'ONCOLOGIST',
  RESET_ONCOLOGIST = 'RESET_ONCOLOGIST'
}
