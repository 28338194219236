export interface IPatient extends IPatientBase {
    objectIdLocality: number,
    objectIdPlanStreet: number,
    houseNumber: string,
    buildingNumber: string,
    appartmentNumber: string,
    gender: string,
    passportIssued: string,
    passportIssuedDate: Date,
    phone: string,
    age: number,
    isElevatedSugarOnce: boolean,
    isWaistToHip: boolean,
    isArterialHypertension: boolean,
    isHigherSystolicAp: boolean,
    isHighCholesterolDrugs: boolean,
    isRelativesDiabetes: boolean,
    isPhysicalActivity: boolean,
    isHarmfulFoodFtw: boolean,
    diabetesCalculationValue: number,
    isElevatedTypeTwoDiabetes: boolean,
		isElevatedCancer:boolean,
		isSmoking: boolean,
    email: string,
    userId: string,
    lastMeal: number,
}

export interface IPatientBase {
  id: number,
  lastName: string,
  firstName: string,
  middleName: string,
  passportSeries: string,
  passportNumber: string,
  snils: string,
  birthDate: any,
  emailSendingStatus: boolean,
  createdAt: string,
}

export interface IPatientState {
  patient: IPatient | null,
  patients: IPatientBase[] | null
}

export enum PatientActionsTypes {
  PATIENTS='PATIENTS',
  PATIENTS_FILTER='PATIENTS_FILTER',
  PATIENT='PATIENT'

}
