import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import {
  ObstetricianGynecologistActionsTypes,
  IObstetricianGynecologistInput,
  IObstetricianGynecologistUpdate
} from "../interfaces/obstetricianGynecologist"
import { ObstetricianGynecologistService } from "../services/obstetricianGynecologist";


export const ObstetricianGynecologistActions = {
    obstetricianGynecologist,
    create,
    update
}
 
function create(data: IObstetricianGynecologistInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return ObstetricianGynecologistService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Заключение акушера-гинеколога добавлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function obstetricianGynecologist(id: number) {
  return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return ObstetricianGynecologistService.obstetricianGynecologist(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: ObstetricianGynecologistActionsTypes.OBSTETRICIAN_GYNECOLOGIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(data: IObstetricianGynecologistUpdate) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return ObstetricianGynecologistService.update(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Данные пациента обновлены успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}
