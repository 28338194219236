/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  InputLabel,
  Stack
} from '@mui/material';
import {
  Field,
  Form,
  Formik
} from 'formik';
import {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { CustomDatePicker } from '../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import { getDisabledForm } from "../../../App/helpers/getDisabledForm";
import { getFullName } from '../../../App/helpers/getFullName';
import { textFieldProps } from '../../../App/helpers/textField';
import { transformData } from "../../../App/helpers/transformData";
import { IRootState } from '../../../App/reducers/store';
import styles from "../../../shared/styles/form";
import { RheumatologistActions } from '../actions/rheumatologist';
import {
  IRheumatologistInput,
  IRheumatologistUpdate
} from '../interfaces/rheumatologist';
import { DirtyActionsTypes } from '../../../App/interface/dirty';
import handleNavigate from '../../../App/helpers/clearButtonNavigate';

const useStyles = styles;

const Rheumatologist = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();

  const { dirty } = useSelector((state: IRootState) => state.dirty);
  const { account } = useSelector((state: IRootState) => state.account);
  const { rheumatologist } = useSelector((state: IRootState) => state.rheumatologist);
  const [loading, setLoading] = useState(false);
  const disableEdit = getDisabledForm(account, id ?? '', location.pathname) ?? false;
  const getRheumatologistConclusion = async (id: number) => {
    await dispatch(RheumatologistActions.rheumatologist(id));
  };

  const navigateToInfo = () => {
    navigate(`/patients/${id}/info`);
  };

  useEffect(() => {
    if (!loading && id) {
      getRheumatologistConclusion(parseInt(id)).then(
        () => setLoading(true),
        () => setLoading(true)
      );
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    const beforeUnloadHandler = function () {
      return true;
    };

    if (dirty) {
      window.onbeforeunload = beforeUnloadHandler;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [dirty])

  const submit = (values: any) => {
    const data = transformData({
      ...values,
      ...{
        id: rheumatologist?.id,
        idPatient: parseInt(id ?? ''),
      },
    });
    dispatch(
      rheumatologist
        ? RheumatologistActions.update(data as IRheumatologistUpdate)
        : RheumatologistActions.create(data as IRheumatologistInput)
    ).then(
      () => {
        dispatch({ type: DirtyActionsTypes.DIRTY, payload: false })
      },
      () => {
      }
    );
  };

  return loading ? (
    <Grid className={classes.main}>
      <Formik
        initialValues={{
          idPatient: rheumatologist?.idPatient,
          conclusion: rheumatologist?.conclusion,
          recommendations: rheumatologist?.recommendations,
          user: getFullName(account),
        }}
        onSubmit={submit}
      >
        {({ setFieldValue, values, dirty }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            dispatch({ type: DirtyActionsTypes.DIRTY, payload: dirty })
          }, [dirty])
          return (
            <Form>
              <Grid container className={classes.formRowWide}>
                <Grid
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100%',
                    },
                  }}
                >
                  <InputLabel>Заключение</InputLabel>
                  <Field
                    multiline
                    maxRows={4}
                    minRows={4}
                    size="small"
                    align="center"
                    type="textarea"
                    variant="outlined"
                    name={'conclusion'}
                    inputProps={textFieldProps}
                    component={TextFieldWithError}
                  />
                </Grid>

                <Grid
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100%',
                    },
                  }}
                >
                  <InputLabel>Рекомендации</InputLabel>
                  <Field
                    multiline
                    maxRows={4}
                    minRows={4}
                    size="small"
                    align="center"
                    type="textarea"
                    variant="outlined"
                    name={'recommendations'}
                    inputProps={textFieldProps}
                    component={TextFieldWithError}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formRow}>
                <Grid
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '8px 14px !important'
                    }
                  }}
                >
                  <InputLabel>Дата</InputLabel>
                  <CustomDatePicker
                    style={{ width: '300px' }}
                    required
                    disableFuture
                    disabled={disableEdit}
                    label="Поступило"
                    inputFormat="dd/MM/yyyy"
                    value={new Date()}
                    views={['day', 'month', 'year']}
                    onChange={(newValue: any) =>
                      setFieldValue('receivedDate', newValue)
                    }
                  />
                </Grid>

                <Grid
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '3rem'
                    },
                  }}
                >
                  <InputLabel>ФИО врача</InputLabel>
                  <Field
                    size="small"
                    align="center"
                    type="text"
                    variant="outlined"
                    name={'user'}
                    sx={{
                      height: '3rem',
                      paddingLeft: '10px',
                      '& .MuiFormControl-root': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                    inputProps={textFieldProps}
                    component={TextFieldWithError}
                  />
                </Grid>
              </Grid>

              {!disableEdit && <Stack
                sx={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 5 }}
                direction="row"
                spacing={2}
              >
                <Button variant="contained" color="success" type="submit">
                  Сохранить данные
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => handleNavigate(dirty, navigateToInfo)}
                >
                  Отменить заполнение
                </Button>
              </Stack>}
            </Form>
          )
            ;
        }}
      </Formik>
    </Grid>
  ) : null;
};

export default Rheumatologist;
