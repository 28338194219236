import Actions from "../../../App/interface/actions";
import {
	RheumatologistActionsTypes,
	IRheumatologistState
} from "../interfaces/rheumatologist";


const init: IRheumatologistState = {
	rheumatologist: null,
};

export function RheumatologistReducer(state: IRheumatologistState = init, action: Actions): IRheumatologistState {
	switch (action.type) {
		case RheumatologistActionsTypes.RHEUMATOLOGIST:
			return {
				...state,
				...{
					rheumatologist: action.payload
				}
			};
		case RheumatologistActionsTypes.RESET_RHEUMATOLOGIST:
			return {
        ...state,
        ...{
          rheumatologist: null
        }
      };
		default:
			return state;
	}
}