import { HttpService } from "../../../App/services/http"
import {
  IColoproctologistInput,
  IColoproctologistUpdate
} from "../interfaces/coloproctologist"

export const ColoproctologistService = {
  coloproctologist,
  update,
  create,
}

function create(data: IColoproctologistInput) {
  return HttpService.post(`conclutions/coloproctologist`, data)
    .then(
      response => {
        return response
      })
}

function coloproctologist(id: number) {
  return HttpService.get(`conclutions/coloproctologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IColoproctologistUpdate) {
  return HttpService.patch(`conclutions/coloproctologist`, data)
    .then(
      response => {
        return response
      })
}
