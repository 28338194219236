export interface ITraumatologistOrthopedistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ITraumatologistOrthopedist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface ITraumatologistOrthopedistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ITraumatologistOrthopedistState {
  traumatologistOrthopedist: ITraumatologistOrthopedist | null
}

export enum TraumatologistOrthopedistActionsTypes {
  TRAUMATOLOGIST_ORTHOPEDIST = 'TRAUMATOLOGIST_ORTHOPEDIST',
  RESET_TRAUMATOLOGIST_ORTHOPEDIST = 'RESET_TRAUMATOLOGIST_ORTHOPEDIST'
}
