export interface ICardiologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ICardiologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface ICardiologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ICardiologistState {
  cardiologist: ICardiologist | null
}

export enum CardiologistActionsTypes {
  CARDIOLOGIST = 'CARDIOLOGIST',
  RESET_CARDIOLOGIST = 'RESET_CARDIOLOGIST'
}
