import { HttpService } from "../../../App/services/http"
import {
  IGastroenterologistInput,
  IGastroenterologistUpdate
} from "../interfaces/gastroenterologist"

export const GastroenterologistService = {
  gastroenterologist,
  update,
  create,
}

function create(data: IGastroenterologistInput) {
  return HttpService.post(`conclutions/gastroenterologist`, data)
    .then(
      response => {
        return response
      })
}

function gastroenterologist(id: number) {
  return HttpService.get(`conclutions/gastroenterologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IGastroenterologistUpdate) {
  return HttpService.patch(`conclutions/gastroenterologist`, data)
    .then(
      response => {
        return response
      })
}
