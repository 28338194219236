import { HttpService } from "../../../App/services/http"
import {
  IOphthalmologistInput,
  IOphthalmologistUpdate
} from "../interfaces/ophthalmologist"

export const OphthalmologistService = {
  ophthalmologist,
  update,
  create,
}

function create(data: IOphthalmologistInput) {
  return HttpService.post(`conclutions/ophthalmologist`, data)
    .then(
      response => {
        return response
      })
}

function ophthalmologist(id: number) {
  return HttpService.get(`conclutions/ophthalmologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IOphthalmologistUpdate) {
  return HttpService.patch(`conclutions/ophthalmologist`, data)
    .then(
      response => {
        return response
      })
}
