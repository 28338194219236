export interface ICardiacSurgeonInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ICardiacSurgeon {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface ICardiacSurgeonUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ICardiacSurgeonState {
  cardiacSurgeon: ICardiacSurgeon | null
}

export enum CardiacSurgeonActionsTypes {
  CARDIAC_SURGEON = 'CARDIAC_SURGEON',
  RESET_CARDIAC_SURGEON = 'RESET_CARDIAC_SURGEON'
}
