import { HttpService } from "../../../App/services/http"
import {
  IRheumatologistInput,
  IRheumatologistUpdate
} from "../interfaces/rheumatologist"

export const RheumatologistService = {
  rheumatologist,
  update,
  create,
}

function create(data: IRheumatologistInput) {
  return HttpService.post(`conclutions/rheumatologist`, data)
    .then(
      response => {
        return response
      })
}

function rheumatologist(id: number) {
  return HttpService.get(`conclutions/rheumatologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IRheumatologistUpdate) {
  return HttpService.patch(`conclutions/rheumatologist`, data)
    .then(
      response => {
        return response
      })
}
