import Actions from "../../../App/interface/actions";
import {
	CardiacSurgeonActionsTypes,
	ICardiacSurgeonState
} from "../interfaces/cardiacSurgeon";


const init: ICardiacSurgeonState = {
	cardiacSurgeon: null,
};

export function CardiacSurgeonReducer(state: ICardiacSurgeonState = init, action: Actions): ICardiacSurgeonState {
	switch (action.type) {
		case CardiacSurgeonActionsTypes.CARDIAC_SURGEON:
			return {
				...state,
				...{
					cardiacSurgeon: action.payload
				}
			};
		case CardiacSurgeonActionsTypes.RESET_CARDIAC_SURGEON:
			return {
				...state,
				...{
					cardiacSurgeon: null
				}
			};
		default:
			return state;
	}
}