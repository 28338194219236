export interface IRheumatologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IRheumatologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IRheumatologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IRheumatologistState {
  rheumatologist: IRheumatologist | null
}

export enum RheumatologistActionsTypes {
  RHEUMATOLOGIST = 'RHEUMATOLOGIST',
  RESET_RHEUMATOLOGIST = 'RESET_RHEUMATOLOGIST'
}
