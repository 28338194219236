export interface IRouteList {
  id: number;
  code: string;
  name: string;
  idFunction: number;
  isActive: boolean;
  comment: string;
  function: IFunction
}

interface IFunction {
  id: number;
  name: string;
  type: number;
}

export interface IRouteListState {
  routeList: IRouteList[] | [];
  activeRouteList: IRouteList[] | [];
}

export enum RouteListActionsTypes {
  ROUTE_LIST = 'ROUTE_LIST',
  ACTIVE_ROUTE_LIST = 'ACTIVE_ROUTE_LIST'
}