import { HttpService } from "../../../App/services/http"
import {
  IOncologistMammologistInput,
  IOncologistMammologistUpdate
} from "../interfaces/oncologistMammologist"

export const OncologistMammologistService = {
  oncologistMammologist,
  update,
  create,
}

function create(data: IOncologistMammologistInput) {
  return HttpService.post(`conclutions/oncologist-mammologist`, data)
    .then(
      response => {
        return response
      })
}

function oncologistMammologist(id: number) {
  return HttpService.get(`conclutions/oncologist-mammologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IOncologistMammologistUpdate) {
  return HttpService.patch(`conclutions/oncologist-mammologist`, data)
    .then(
      response => {
        return response
      })
}
