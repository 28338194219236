export interface IGastroenterologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IGastroenterologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IGastroenterologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IGastroenterologistState {
  gastroenterologist: IGastroenterologist | null
}

export enum GastroenterologistActionsTypes {
  GASTROENTEROLOGIST = 'GASTROENTEROLOGIST',
  RESET_GASTROENTEROLOGIST = 'RESET_GASTROENTEROLOGIST'
}
