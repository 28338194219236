import { HttpService } from "../../../App/services/http"
import {
  ITraumatologistOrthopedistInput,
  ITraumatologistOrthopedistUpdate
} from "../interfaces/traumatologistOrthopedist"

export const TraumatologistOrthopedistService = {
  traumatologistOrthopedist,
  update,
  create,
}

function create(data: ITraumatologistOrthopedistInput) {
  return HttpService.post(`conclutions/traumatologist-orthopedist`, data)
    .then(
      response => {
        return response
      })
}

function traumatologistOrthopedist(id: number) {
  return HttpService.get(`conclutions/traumatologist-orthopedist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ITraumatologistOrthopedistUpdate) {
  return HttpService.patch(`conclutions/traumatologist-orthopedist`, data)
    .then(
      response => {
        return response
      })
}
