export interface IObstetricianGynecologistInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IObstetricianGynecologist {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IObstetricianGynecologistUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface IObstetricianGynecologistState {
  obstetricianGynecologist: IObstetricianGynecologist | null
}

export enum ObstetricianGynecologistActionsTypes {
  OBSTETRICIAN_GYNECOLOGIST = 'OBSTETRICIAN_GYNECOLOGIST',
  RESER_OBSTETRICIAN_GYNECOLOGIST = 'RESER_OBSTETRICIAN_GYNECOLOGIST'
}
