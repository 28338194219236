import Actions from "../../../App/interface/actions";
import {
	NeurosurgeonActionsTypes,
	INeurosurgeonState
} from "../interfaces/neurosurgeon";


const init: INeurosurgeonState = {
	neurosurgeon: null,
};

export function NeurosurgeonReducer(state: INeurosurgeonState = init, action: Actions): INeurosurgeonState {
	switch (action.type) {
		case NeurosurgeonActionsTypes.NEUROSURGEON:
			return {
				...state,
				...{
					neurosurgeon: action.payload
				}
			};
		case NeurosurgeonActionsTypes.RESET_NEUROSURGEON:
			return {
				...state,
				...{
					neurosurgeon: null
				}
			};
		default:
			return state;
	}
}