export interface INeurosurgeonInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface INeurosurgeon {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface INeurosurgeonUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface INeurosurgeonState {
  neurosurgeon: INeurosurgeon | null
}

export enum NeurosurgeonActionsTypes {
  NEUROSURGEON = 'NEUROSURGEON',
  RESET_NEUROSURGEON = 'RESET_NEUROSURGEON'
}
