import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import {
  TraumatologistOrthopedistActionsTypes,
  ITraumatologistOrthopedistInput,
  ITraumatologistOrthopedistUpdate
} from "../interfaces/traumatologistOrthopedist"
import { TraumatologistOrthopedistService } from "../services/traumatologistOrthopedist";


export const TraumatologistOrthopedistActions = {
    traumatologistOrthopedist,
    create,
    update
}
 
function create(data: ITraumatologistOrthopedistInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return TraumatologistOrthopedistService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Заключение травматолога-ортопеда добавлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function traumatologistOrthopedist(id: number) {
  return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return TraumatologistOrthopedistService.traumatologistOrthopedist(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: TraumatologistOrthopedistActionsTypes.TRAUMATOLOGIST_ORTHOPEDIST, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(data: ITraumatologistOrthopedistUpdate) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return TraumatologistOrthopedistService.update(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Данные пациента обновлены успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}
