export interface ISurgeonInput {
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ISurgeon {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface ISurgeonUpdate {
  id: number,
  idPatient: number,
  conclusion: string,
  recommendations: string
}

export interface ISurgeonState {
  surgeon: ISurgeon | null
}

export enum SurgeonActionsTypes {
  SURGEON = 'SURGEON',
  RESET_SURGEON = 'RESET_SURGEON'
}
