/* eslint-disable react-hooks/exhaustive-deps */
import AddCardIcon from '@mui/icons-material/AddCard';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { getFullName } from '../../../App/helpers/getFullName';
import { IRootState } from '../../../App/reducers/store';
import { PatientActions } from '../../../features/Patient/actions/patient';
import { IPatientBase } from '../../../features/Patient/interfaces/patient';
import { sections } from '../../../shared/data/PatientsReestrTabs';
import { Skeleton } from '@mui/lab';
import { DirtyActionsTypes } from '../../../App/interface/dirty';

const useStyles = makeStyles(() => ({
  main: {
    height: 'calc(100vh - 73px)',
    flexDirection: 'row',
  },
  left: {
    flex: '240px',
    width: '240px',
    position: 'relative',
  },
  right: {
    flex: 'calc(100% - 240px)',
    width: 'calc(100% - 240px)',
    paddingLeft: '20px',
    paddingTop: '20px',
    boxSizing: 'border-box',
    paddingRight: '20px',
  },
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
  },
  side_menu_container: {
    display: 'grid',
    gridTemplateRows: '390px 1fr',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.1)',
  },
  menu_container: {
    '& .MuiTabs-root': {
      borderRight: 'none',
    },
  },
  filter_container: {
    height: '100%',
  },
  tabsContainer: {
    height: '100%',
  },
  tab_list: {
    height: '100%',
    width: '240px',
    paddingTop: '10px',
    position: 'fixed',
    color: '#495055',
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '& .MuiTabs-indicator': {
      left: 0,
      width: '4px',
      backgroundColor: '#2595FF',
      borderRadius: '0px 4px 4px 0px',
    },
  },
  tab: {
    height: '40px',
    color: '#000',
    fontWeight: 400,
    fontSize: '14px',
    minHeight: '40px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'none',
    justifyContent: 'flex-start',
    '&.Mui-selected': {
      color: '#2595FF',
      backgroundColor: '#F1F9FF',
    },
  },
  table: {
    '& a': {
      color: '#0067a2',
      textDecoration: 'none',
    },
    '& thead': {
      backgroundColor: '#e1e1e1',
      '& th': {
        borderBottom: '1px solid #aaa',
      },
    },
    '& tbody>tr:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  },
  loading: {
    textAlign: 'center',
    padding: '50px 0',
  },
}));
const Patients = () => {
  const dispatch: any = useDispatch();
  const classes = useStyles();

  const { patients } = useSelector((state: IRootState) => state.patients);

  const [tabId, setTabId] = useState('');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchQuery] = useDebounce(search, 900);
  const getPatients = async () => {
    await dispatch(
      PatientActions.patients(
        searchQuery !== ''
          ? {
              search: searchQuery,
            }
          : {}
      )
    );
  };
  useEffect(() => {
    if (!loading) {
      getPatients().then(
        () => {
          dispatch({ type: DirtyActionsTypes.DIRTY, payload: false });
          setLoading(true);
        },
        () => {
          setLoading(true);
        }
      );
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (tabId === '') {
      let currentPage = sections.filter((el) =>
        window.location.pathname.includes(el.path)
      );
      if (
        currentPage.length > 0 &&
        typeof currentPage[0]['id'] !== 'undefined'
      ) {
        setTabId(currentPage[0].id);
      }
    }
  }, []);

  const searchHandle = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const skeletonTable = () => {
    let rows = [];
    let skeleton = <Skeleton variant="rounded" width={'100%'} height={25} />;
    for (let i = 0; i < 10; i++) {
      rows.push(
        <TableRow key={i}>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
          <TableCell>{skeleton}</TableCell>
        </TableRow>
      );
    }
    return <>{rows}</>;
  };

  return (
    <>
      <Typography variant="h5" className={'pageTitle'}>
        Реестр пациентов
      </Typography>
      <Card>
        <CardContent>
          <Grid container sx={{ padding: '0px 0 10px 0' }}>
            <Grid item sx={{ paddingRight: '15px' }}>
              <Button
                variant="outlined"
                href={'/patients/registration'}
                startIcon={<AddCardIcon />}
              >
                Добавить карту пациента
              </Button>
            </Grid>
          </Grid>

          <Grid item sx={{ paddingTop: '10px', paddingBottom: '20px' }}>
            <Input
              id="search"
              placeholder="Введите ФИО, СНИЛС или паспорт"
              onChange={searchHandle}
              style={{
                height: '35px',
                width: '500px',
                borderRadius: '6px',
                backgroundColor: 'white',
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <TableContainer className={classes.table} component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>ФИО</TableCell>
                  <TableCell align="right">Дата рождения</TableCell>
                  <TableCell align="right">СНИЛС</TableCell>
                  <TableCell align="right">Паспорт</TableCell>
                  <TableCell align="right">Дата создания</TableCell>
                  <TableCell align="right">Заключение отправлено</TableCell>
                  <TableCell sx={{ width: '40px' }} align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patients?.map((patient: IPatientBase) => (
                  <TableRow key={patient.id}>
                    <TableCell>{patient.id}</TableCell>
                    <TableCell>
                      <a href={`/patients/${patient.id}`}>
                        {getFullName(patient)}
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      {patient.birthDate
                        ? new Date(patient.birthDate).toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell align="right">{patient.snils}</TableCell>
                    <TableCell align="right">{`${
                      patient.passportSeries ?? ''
                    } ${patient.passportNumber ?? ''} `}</TableCell>
                    <TableCell align="right">
                      {patient.createdAt
                        ? new Date(patient.createdAt).toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell align="right">
                      {patient.emailSendingStatus === true ? 'Да' : 'Нет'}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        title="Редактировать"
                        startIcon={<EditIcon />}
                        href={`/patients/${patient.id}`}
                      ></Button>
                    </TableCell>
                  </TableRow>
                ))}
                {!patients && skeletonTable()}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};
export default Patients;
