import Actions from "../../../App/interface/actions";
import {
	OncologistActionsTypes,
	IOncologistState
} from "../interfaces/oncologist";


const init: IOncologistState = {
	oncologist: null,
};

export function OncologistReducer(state: IOncologistState = init, action: Actions): IOncologistState {
	switch (action.type) {
		case OncologistActionsTypes.ONCOLOGIST:
			return {
				...state,
				...{
					oncologist: action.payload
				}
			};
		case OncologistActionsTypes.RESET_ONCOLOGIST:
			return {
        ...state,
        ...{
          oncologist: null
        }
      };
		default:
			return state;
	}
}