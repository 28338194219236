import Actions from "../../../App/interface/actions";
import {
	ColoproctologistActionsTypes,
	IColoproctologistState
} from "../interfaces/coloproctologist";


const init: IColoproctologistState = {
	coloproctologist: null,
};

export function ColoproctologistReducer(state: IColoproctologistState = init, action: Actions): IColoproctologistState {
	switch (action.type) {
		case ColoproctologistActionsTypes.COLOPROCTOLOGIST:
			return {
				...state,
				...{
					coloproctologist: action.payload
				}
			};
		case ColoproctologistActionsTypes.RESET_COLOPROCTOLOGIST:
			return {
        ...state,
        ...{
          coloproctologist: null
        }
      };
		default:
			return state;
	}
}