import { HttpService } from "../../../App/services/http"
import {
  INeurosurgeonInput,
  INeurosurgeonUpdate
} from "../interfaces/neurosurgeon"

export const NeurosurgeonService = {
  neurosurgeon,
  update,
  create,
}

function create(data: INeurosurgeonInput) {
  return HttpService.post(`conclutions/neurosurgeon`, data)
    .then(
      response => {
        return response
      })
}

function neurosurgeon(id: number) {
  return HttpService.get(`conclutions/neurosurgeon/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: INeurosurgeonUpdate) {
  return HttpService.patch(`conclutions/neurosurgeon`, data)
    .then(
      response => {
        return response
      })
}
